import Vue from 'vue';
import Lightbox from '../components/Lightbox.vue';
import Icon from '../components/base/Icon.vue';

export default {
    inserted(element: HTMLImageElement) {
        if (element.tagName.toLowerCase() !== 'img' && !element.dataset.lbYoutubeid && !element.dataset.lbVimeolink) {
            // not an image -> do nothing
            return;
        }
        const caption = element.dataset.lbCaption;
        const description = element.dataset.lbDescription;
        const image = element.dataset.original;
        const youtubeId = element.dataset.lbYoutubeid;
        const vimeoLink = element.dataset.lbVimeolink;
        const parent = element.parentElement;
        const style = element.dataset.lbStyle || 'default';

        const indicator = document.createElement('span');
        if (style === 'gray') {
            indicator.classList.add('lightbox-indicator-gray');
        } else if (style === 'small') {
            indicator.classList.add('lightbox-indicator-small');
        } else {
            indicator.classList.add('lightbox-indicator');
        }
        // mount icon
        parent.append(indicator);
        const ComponentClass = Vue.extend(Icon);
        const icon = new ComponentClass({
            propsData: { name: 'maximize-outline' }
        });
        const iconContainer = document.createElement('div');
        iconContainer.classList.add('w-full', 'h-full');
        indicator.append(iconContainer);
        icon.$mount(iconContainer);
        icon.$el.classList.add('w-full', 'h-full', 'fill-current');
        parent.classList.add('cursor-pointer', 'lightbox', 'group');
        parent.onclick = () => {
            // disable scrolling on body
            document.body.classList.add('overflow-hidden');

            // mount lightbox component
            const container = document.createElement('div');
            container.id = 'lightbox';
            document.getElementById('app').append(container);
            const ComponentClass = Vue.extend(Lightbox);
            const instance = new ComponentClass({
                propsData: { image, caption, description, youtubeId, vimeoLink }
            });
            instance.$on('close', () => {
                // unmount lightbox
                instance.$destroy();
                instance.$el.parentElement.removeChild(instance.$el);
                // re-enable scrolling on body
                document.body.classList.remove('overflow-hidden');
            });
            instance.$mount('#lightbox');
        };
    }
};
