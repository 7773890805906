<template>
    <div>
        <slot />
        <div class="hidden grid-cols-12 gap-x-8 items-start lg:grid">
            <div class="z-10 col-span-5 col-start-1 row-start-1">
                <div v-for="(client, c) in limitedClients" :key="`${_uid}-${client.uuid}-${c}`">
                    <a v-if="client.link"
                       :class="{ 'text-gray-600 mr-40': !client.active }"
                       :href="client.link"
                       :target="client.link.includes('http') ? '_blank' : '_self'"
                       :aria-label="client.name"
                       class="flex justify-between py-10 border-b border-current transition-all cursor-pointer"
                       @mouseenter="hover(client)">
                        <div :class="{ '-mr-40': !client.active }" class="transition-all">
                            <div class="uppercase transition-colors tracking-wider">{{ client.kicker }}</div>
                            <div class="py-2 transition-colors text-3xl">{{ client.name }}</div>
                        </div>
                        <div :class="[client.active ? 'opacity-100' : 'opacity-0', {'bg-black text-white': contrast, 'bg-gray-50': semi, 'bg-white': !contrast && !semi  }]"
                             class="flex shrink-0 justify-center items-center self-center w-14 h-14 rounded-full transition-opacity">
                            <icon class='w-9 h-9 fill-current' name='chevron-right' />
                        </div>
                    </a>
                    <div v-else
                       :class="{ 'text-gray-600 mr-40': !client.active }"
                       class="flex justify-between py-10 border-b border-current transition-all"
                       @mouseenter="hover(client)">
                        <div :class="{ '-mr-40': !client.active }" class="transition-all">
                            <div class="uppercase transition-colors tracking-wider">{{ client.kicker }}</div>
                            <div class="py-2 transition-colors text-3xl">{{ client.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-start-5 row-start-1" :class="portrait ? 'col-span-5': 'col-span-8'">
                <div class="relative bg-gray-400" :class="portrait ? 'pt-5/6' : 'pt-5/3'">
                    <template v-for="(client, c) in limitedClients">
                        <img v-if="client.image"
                             :key="`img-${_uid}-${client.uuid}-${c}`"
                             :class="[client.active ? 'opacity-100' : 'opacity-0']"
                             :src="client.image"
                             :alt="client.imageAlt"
                             class="object-cover object-center absolute top-0 left-0 w-full h-full transition-opacity duration-500">
                    </template>
                </div>
            </div>
            <div v-if="active"
                 :class="{ 'bg-black text-white': contrast, 'bg-gray-100': semi, 'bg-white': !contrast && !semi }"
                 class="z-10 col-span-7 col-start-6 px-16 py-12 -mt-28 leading-tight links">
                <span class="line-clamp-4" v-html="active.description"></span>
                <a v-if="active.link" :href="active.link" class="flex items-center mt-2 text-lg font-medium" :target="active.link.includes('http') ? '_blank' : '_self'" :aria-label="active.name">
                    <icon class="mr-1.5 w-5 h-5 fill-current" name="chevron-right" />
                    <span class="ml-1">{{ active.linkLabel ? active.linkLabel : active.link.startsWith('http') ? linkVisit : linkLabel }}</span>
                </a>
                <img v-if="active.logo" :src="active.logo" :alt="active.logoAlt" class="mt-8 h-14 w-64 object-contain object-left">
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Provide } from 'vue-property-decorator';
import Icon from './base/Icon.vue';

@Component({
    components: { Icon }
})
class ClientOverview extends Vue {
    @Provide('registerClient') childRegistration = this.registerClient;
    @Prop({ required: true }) linkLabel: string;
    @Prop({ required: true }) linkVisit: string;
    @Prop({ default: false }) contrast: boolean;
    @Prop({ default: false }) semi: boolean;
    @Prop({ default: true }) portrait: boolean;

    clients = [];

    get active() {
        return this.clients.find(c => c.active);
    }

    get limitedClients() {
        return this.clients.slice(0, 3);
    }

    registerClient(client) {
        this.clients.push(client);
        if (this.$children.filter(this.filterClients).length === this.clients.length) {
            if (this.clients.length > 0) {
                setTimeout(() => {
                    this.clients[0].toggle();
                });
            }
        }
    }

    hover(client) {
        this.clients.forEach(c => {
            c.active = false;
        });
        client.active = true;
    }

    filterClients(child: Vue) {
        return child.$vnode && child.$vnode.componentOptions && child.$vnode.componentOptions.tag === 'client';
    }
}

export default Vue.component('client-overview', ClientOverview);

</script>
