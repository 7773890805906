<template>
    <form novalidate @keydown="handleKeyDown" @submit.prevent="submit">
        <collapse-transition v-show="!collapsed">
            <div :class="formClass">
                <input type="hidden" :value="topic" name="preselection">
                <input type="hidden" :value="currentPage" name="page">
                <slot :submitted="submitted"
                      :success="success"
                      :notvalid="notvalid"
                      :emailInvalid="emailInvalid"
                      :fieldset="currentFieldset"
                      :topic="topic"
                      :loading="loading"
                      :update-topic="updateTopic"
                      :close="close" />
            </div>
        </collapse-transition>
        <slot name="collapsed"
              :expand="expand"
              :collapsed="collapsed"
              :has-image="hasImage"
              :update-topic="updateTopic"
              :emailInvalid="emailInvalid"
              :notvalid="notvalid"
              :submitted="submitted" />
    </form>
</template>

<script lang="ts">
import { getTwConfig } from './utils';

const FORM_ENDPOINT = '/.rest/api/v1/cta';
const VALIDATE_MAIL_ENDPOINT = '/.rest/api/v1/cta/validate-email';

const twConfig = getTwConfig();

export default {
    name: 'ContactForm',
    props: {
        node: String,
        fieldset: {
            type: String,
            default: 'small'
        },
        preselection: {
            type: String,
            default: 'contact'
        },
        emailError: {
            type: String,
            required: true
        },
        collapsable: {
            type: Boolean,
            default: false
        },
        hasImage: {
            type: Boolean,
            default: false
        },
        formClass: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            notvalid: false,
            submitted: false,
            success: false,
            loading: false,
            topic: this.preselection,
            currentFieldset: this.fieldset,
            collapsed: this.collapsable,
            emailInvalid: false
        };
    },
    mounted() {
        // get the URL parameter here
        if (this.id) {
            const urlParams = new URLSearchParams(window.location.search);
            const collapsedParam = urlParams.get(this.id + '-form');
            setTimeout(() => {
                this.collapsed = !(collapsedParam === 'true');
            }, 500);
        }
    },
    methods: {
        updateTopic(topic) {
            this.topic = topic;
            this.currentFieldset = topic !== 'business' && this.fieldset !== 'large' ? 'small' : this.fieldset;
        },
        async validateEmail(emailField: HTMLInputElement) {
            const email = emailField.value;
            // check if is a valid email
            if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(email)) {
                // if business check if is corporate
                if (this.topic === 'business' || this.topic === 'newsletter') {
                    const res = await fetch(`${this.$contextPath}${VALIDATE_MAIL_ENDPOINT}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ email })
                    });
                    const valid = await res.json();
                    this.notvalid = this.notvalid || !valid;
                    this.emailInvalid = !valid;
                    return;
                } else {
                    this.notvalid = this.notvalid || false;
                    this.emailInvalid = false;
                    return;
                }
            }
            this.emailInvalid = true;
            this.notvalid = true;
        },
        // don't submit when press Enter
        handleKeyDown(event) {
            if (event.key === 'Enter') {
                event.preventDefault();
                const currentField = event.target;
                const currentLabel = currentField.parentNode;
                if (currentField.nodeName === 'TEXTAREA') {
                    currentField.value += '\n';
                }
                // go to next field if is text (don't work with dropdown or autocomplete)
                const nextLabel = currentLabel.nextElementSibling;
                if (nextLabel && nextLabel.tagName === 'LABEL' && currentField.id !== 'country') {
                    const nextField = nextLabel.querySelector('input');
                    if (nextField) {
                        nextField.focus();
                    }
                }
            }
        },
        async submit({ target }) {
            this.notvalid = false;
            const form: HTMLFormElement =
                target.tagName.toLowerCase() === 'form' ? target : target.form;
            form.querySelector('input.b-t').removeAttribute('required');
            const formInputs = target.elements;
            for (let i = 0; i < formInputs.length; i++) {
                if (formInputs[i].type === 'email') {
                    await this.validateEmail(formInputs[i]);
                } else if (formInputs[i].name !== 'name' && (
                    formInputs[i].required && (!formInputs[i].value || (formInputs[i].type === 'checkbox' && !formInputs[i].checked)))
                ) {
                    this.notvalid = true;
                }
            }
            if (this.notvalid) {
                form.querySelector('input.b-t').setAttribute('required', 'required');
                return;
            }

            this.loading = true;
            this.submitted = false;
            const fd: FormData = new FormData(form);
            // set page url field
            fd.set('page', location.pathname);

            try {
                const res = await fetch(
                    `${this.$contextPath}${FORM_ENDPOINT}/${this.$site}/${this.$lang}/${this.node}`,
                    {
                        method: 'POST',
                        body: fd
                    }
                );
                if (window.dataLayer) {
                    const dataLayer = window.dataLayer || [];
                    dataLayer.push({
                        event: 'formSubmit',
                        preselection: fd.get('preselection')
                    });
                }
                const data = await res.json();
                this.success = data.success;
            } catch (e) {
                this.success = false;
            }
            this.submitted = true;
            this.loading = false;
        },
        expand() {
            this.collapsed = false;
            //  put the URL parameter
            if (this.id) {
                const urlParams = new URLSearchParams(window.location.search);
                urlParams.set(this.id + '-form', 'true');
                const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
                window.history.replaceState({}, '', newUrl);
            }
        },
        close() {
            this.collapsed = true;
        },
        breakpointSize(name: string): number {
            if (Object.prototype.hasOwnProperty.call(twConfig.theme.screens, name)) {
                return parseInt(twConfig.theme.screens[name]);
            }
            return -1;
        }
    },
    computed: {
        currentPage(): string {
            return location.pathname;
        }
    }
};
</script>
