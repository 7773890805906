import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default {
    bind(element: HTMLElement) {
        element.style.setProperty('--smooth-progress', '0');
        let startScroll = 0;
        gsap.to(element, {
            scrollTrigger: {
                trigger: element,
                // start when page is scrolled
                // needed because the element is showed at the viewport when entered the page
                start: 'top bottom',
                // End end the bottom of the element getsout of viewport
                end: 'bottom bottom',
                // smooth transition
                scrub: true,
                onUpdate: self => {
                    if (window.scrollY === 0) {
                        startScroll = self.progress > 0 ? self.progress : 0;
                    }
                    // calculate the new progress by the startScroll
                    element.style.setProperty('--smooth-progress', ((self.progress - startScroll) / (1 - startScroll)).toFixed(2));
                }
            }
        });
    }
};
