<template>
    <div :class="parentClass">
        <div
            :class="itemClass"
            v-for="icon in icons"
            ref="share-list-items"
            :data-type="icon"
            :id="'tr-share-' + icon"
            :key="icon">
            <icon
                :name="icon"
                class="pointer-events-none w-full h-full text-current transition-colors fill-current" />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Ref } from 'vue-property-decorator';
import JSShare from 'js-share';

import Icon from './base/Icon.vue';

@Component({
    components: { Icon }
})
export default class ShareList extends Vue {
    @Prop({ default: '' }) parentClass: string;
    @Prop({ default: '' }) itemClass: string;
    @Ref('share-list-items') shareItems: HTMLElement[];

    icons = ['linkedin', 'facebook', 'twitter'];

    mounted() {
        for (let i = 0; i < this.shareItems.length; i += 1) {
            this.shareItems[i].addEventListener('click', function share() {
                return JSShare.go(this);
            });
        }
    }
}
</script>
