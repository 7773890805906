<template>
    <fixed-scroll-button :num-items="icons.length" :open-down="openDown">
        <template #default="{ getPos }">
            <div
                class="hidden lg:block absolute bottom-0 p-4 w-14 h-14 text-4xl text-white bg-black rounded-full border border-white transition-all cursor-pointer group hover:bg-white hover:border-black"
                v-for="(icon, i) in icons"
                ref="share-items"
                :data-type="icon"
                :style="getPos(i, false)"
                :id="'tr-share-' + icon"
                :key="icon">
                <icon :name="icon"
                      class="pointer-events-none w-full h-full text-white transition-colors fill-current group-hover:text-black" />
            </div>
            <div
                class="absolute lg:hidden bottom-0 p-4 w-14 h-14 text-4xl text-white bg-black rounded-full border border-white transition-all cursor-pointer group hover:bg-white hover:border-black"
                v-for="(icon, i) in icons"
                ref="share-items"
                :data-type="icon"
                :style="getPos(i, true)"
                :id="'tr-share-' + icon"
                :key="icon+'-mobile'">
                <icon :name="icon"
                      class="pointer-events-none w-full h-full text-white transition-colors fill-current group-hover:text-black" />
            </div>
        </template>
        <template #toggle="{ toggle, contrast, hidden, open }">
            <slot :toggle="toggle" :contrast="contrast" :hidden="hidden" :open="open" />
        </template>
    </fixed-scroll-button>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Ref } from 'vue-property-decorator';
import JSShare from 'js-share';

import Icon from './base/Icon.vue';

@Component({
    components: { Icon }
})
export default class ShareButton extends Vue {
    @Prop({ default: false, type: Boolean }) openDown: boolean;
    @Ref('share-items') shareItems: HTMLElement[];

    hidden = true;
    icons = ['facebook', 'twitter', 'xing', 'linkedin'];
    trigger = null;

    mounted() {
        for (let i = 0; i < this.shareItems.length; i += 1) {
            this.shareItems[i].addEventListener('click', function share(e) {
                if (this.dataset.type === 'xing') {
                    // custom handler for xing since it's not supported by the library
                    const url = `https://www.xing.com/spi/shares/new?url=${encodeURIComponent(location.href)}`;
                    window.open(url, '_blank');
                    return;
                }
                return JSShare.go(this);
            });
        }
    }
}
</script>
