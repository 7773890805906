<template>
    <div v-if="!registered" class="hidden">
        <slot />
    </div>
</template>

<script lang="ts">
import OverviewCategory from './OverviewCategory.vue';
import Vue from 'vue';
import { Component, Inject, Prop, Provide, Ref } from 'vue-property-decorator';
import Dropdown from '../Dropdown.vue';

@Component
export default class AsyncOverviewFilter extends Vue {
    @Ref('dropdown') dropdown: Dropdown;
    @Prop({ required: true }) name: string;
    @Prop({ required: true }) filterKey: string;
    @Prop({ required: true }) index: number;
    @Prop({ required: true }) chooseLabel: string;
    @Prop({ default: false }) contrast: boolean;
    @Prop({ default: false }) isEditMode: boolean;

    categories = [];
    selectedCategory = '';
    registered = false;

    @Provide('registerOverviewCategory') registerOverviewCategory = this.registerCategory;

    @Inject() registerOverviewFilter: (filter: InstanceType<typeof AsyncOverviewFilter>) => undefined;

    mounted() {
        this.registerOverviewFilter(this);
    }

    registerCategory(category: InstanceType<typeof OverviewCategory>) {
        this.categories.push(category);
        if (this.$children.filter(this.filterItems).length === this.categories.length) {
            this.registered = true;
        }
    }

    // check children to only count slide components
    filterItems(child: Vue) {
        return child.$vnode && child.$vnode.componentOptions && child.$vnode.componentOptions.tag === 'overview-category';
    }
}
</script>
