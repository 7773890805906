<template>
    <div>
        <div v-if="years.length > 1"
             class="pt-12 pb-8 my-14"
             :class="[contrast ? 'bg-gray-800' : 'bg-gray-100']">
            <div class="container flex overflow-x-auto flex-nowrap space-x-2 scrollbar-hide">
                <div v-for="year in years"
                     :key="`${_uid}:${year}`"
                     @click="activeYear = year"
                     class="px-4 pt-2 text-center border-current cursor-pointer lg:px-8"
                     :class="[activeYear === year ? 'border-t-3' : 'border-t text-gray-600 mt-px hover:border-t-2', contrast ? 'hover:text-white' : 'hover:text-black']">
                    <template v-if="year">{{ year }}</template>
                    <template v-else>More</template>
                </div>
            </div>
        </div>
        <div class="container pr-0 pb-16 lg:pb-20">
            <div class="overflow-x-auto pb-9 scrollbar reports-section">
                <table class="pr-9 w-full table-fixed lg:pr-0">
                    <tr class="border-current border-b-3">
                        <template v-if="!hideQuarters">
                            <th class="sticky left-0 w-32"></th>
                            <th v-for="quarter in quarters"
                                :key="`${_uid}:${quarter}`"
                                class="px-6 py-4 w-32 text-left uppercase">
                                {{ quarter }}
                            </th>
                        </template>
                    </tr>
                    <template v-for="category in categories">
                        <template v-if="reportsByYearWithCategory(category.id).length">
                            <tr :key="category.id" class="border-b border-gray-400">
                                <td class="sticky left-0 w-32 break-words hyphens-auto" :class="[contrast ? 'bg-black' : 'bg-white']">
                                    {{ category.displayName }}
                                </td>
                                <template v-if="hideQuarters">
                                    <td class="align-top break-words hyphens-auto"
                                        v-for="(quarter, r) in numColumns"
                                        :key="`${_uid}:${category.id}:${r}`">
                                        <a v-for="report in getReportsByYearCategoryQuarter(category.id, quarter)"
                                           :aria-labelledby="report.name"
                                           :key="report.name"
                                           :href="report.file"
                                           class="block p-4"
                                           target="_blank">
                                            <icon :name="[report.file.startsWith('http') ? 'external-link' : 'download-outline']"
                                                  class="mb-3 w-5 h-5 fill-current " :class="[contrast ? 'text-white' : 'text-berry-600']" />
                                            <div>
                                                {{ report.name }}
                                            </div>
                                        </a>
                                    </td>
                                </template>
                                <template v-else>
                                    <td v-for="(quarter, r) in quarters" :key="`${_uid}:${category.id}:${r}`" class="break-words hyphens-auto">
                                        <template v-if="getReport(category.id, quarter)">
                                            <a :href="getReport(category.id, quarter).file"
                                               class="block p-4"
                                               target="_blank">
                                                <icon :name="[getReport(category.id, quarter).file.startsWith('http') ? 'external-link' : 'download-outline']"
                                                      :class="[contrast ? 'text-white' : 'text-berry-600']"
                                                      class="mb-3 w-5 h-5 fill-current" />
                                                <div>
                                                    {{ getReport(category.id, quarter).name }}
                                                </div>
                                            </a>
                                        </template>
                                    </td>
                                </template>
                            </tr>
                        </template>
                    </template>
                </table>
                &nbsp;
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Icon from './base/Icon.vue';

interface Report {
    name: string;
    description: string;
    file: string;
    year: string;
    quarter: string;
    categoryId: string;
}

@Component({
    components: { Icon }
})
class ReportsTable extends Vue {
    @Prop() reports: Report[];
    @Prop() categories: { displayName: string; id: string }[];
    @Prop() years: string[];
    @Prop() quarters: string[];
    @Prop({ default: false, type: Boolean }) contrast: boolean;
    @Prop({ default: false, type: Boolean }) hideYears: boolean;
    @Prop({ default: false, type: Boolean }) hideQuarters: boolean;
    @Prop({ default: false, type: Boolean }) hideCategories: boolean;

    activeYear = '';

    mounted() {
        if (this.years.length) {
            this.activeYear = this.years[0];
        }
    }

    getReportsByYearCategoryQuarter(categoryId: string, quarter): Report[] {
        const reports = this.reports.filter(r => r.year === this.activeYear && r.categoryId === `category:${categoryId}`);
        if (this.hideQuarters) {
            return reports.length >= quarter ? [reports[quarter - 1]] : [];
        }
        return reports;
    }

    getReport(categoryId: string, quarter: string): Report {
        return this.reportsByYearWithCategory(categoryId).find(r => r.quarter === quarter);
    }

    reportsByYearWithCategory(categoryId: string): Report[] {
        return this.reports.filter(r => r.year === this.activeYear && r.categoryId === `category:${categoryId}`);
    }

    /**
     * max number of columns -> category with the most reports/year
     */
    get numColumns() {
        if (this.hideQuarters) {
            return this.categories.reduce((max, cat) => this.reportsByYearWithCategory(cat.id).length > max ? this.reportsByYearWithCategory(cat.id).length : max, 0);
        }
        return this.quarters.length;
    }
}

export default Vue.component('reports-table', ReportsTable);

</script>
