<template>
    <div>
        <div class="-mx-9 lg:hidden">
            <button class="px-9 w-full text-left focus:outline-none" @click="toggle">
                <span :class="{ 'text-gray-600 border-b': !active }"
                      class="flex justify-between items-center pt-12 pb-6">
                    <span class="block w-full">
                        <span :class="{ 'uppercase tracking-wider': !sophos, 'text-berry-600': sophos && active }" class="flex items-center justify-between">
                            {{ title }}
                            <icon :class="[contrast ? 'text-white' : 'text-black']"
                                  :name="[ active ? 'minus' : 'plus']"
                                  class="shrink-0 ml-auto w-5 h-5 fill-current" />
                        </span>
                    </span>
                </span>
            </button>
            <collapse-transition>
                <div v-show="active" :class="[contrast ? 'bg-black text-white' : 'text-black']" class="px-9 py-10">
                    <div class="relative"
                         :class="{ 'h-[22rem]': rendition === 'portrait', 'h-96 py-4': rendition === 'reel', 'py-10': rendition !== 'reel' }">
                        <div v-if="rendition && active" class="absolute grid" :class="{
                                   'bg-gray-200': !contrast && !sophos,
                                   'bg-berry-500/20': !contrast && sophos,
                                   'bg-gray-800': contrast && !sophos,
                                   'bg-berry-700': contrast && sophos,
                                   'w-1/2 md:w-1/6 h-2/3 top-1/6 -right-9 md:right-1/3': rendition === 'reel',
                                   'w-1/2 md:w-1/3 h-full top-1/2 -translate-y-1/2 right-1/6 md:right-1/4' : rendition === 'landscape',
                                   'w-1/2 -right-9 md:right-1/4 top-0 h-full': rendition === 'portrait',
                                   'opacity-100' : active,
                                   'opacity-0' : !active,
                                   'rounded-l-lg': sophos
                                }"></div>
                        <div class="grid relative z-10 w-full"
                             :class="[rendition === 'landscape' && type === 'image' ? 'h-0 pt-16/9' : 'h-full']">
                            <div v-if="(type !== 'image' || !type) && active"
                                 :key="`video-${uuid}`"
                                 v-proto.inline.top="'Video'"
                                 class="relative h-0 pt-16/9 top-0 left-0">
                                <gft-video v-if="type === 'vimeoLink' && active"
                                           :key="`vimeo-${uuid}`"
                                           v-proto.inline.top="'Embedded Link'"
                                           :overlay="overlay"
                                           :link="media"
                                           :edit-mode="isEditMode"
                                           :image="thumbnail"
                                           class="object-contain absolute w-full h-full top-0 left-0 object-left"></gft-video>
                                <template v-if="type === 'youtubeId' && active">
                                    <iframe v-if="isEditMode"
                                            class="cookieconsent-optin-marketing object-contain absolute w-full h-full top-0 left-0 object-left"
                                            :src="`https://www.youtube.com/embed/${media}?playsinline=1&showinfo=0&rel=0`"
                                            frameborder="0"
                                            data-cookieconsent="marketing"
                                            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen></iframe>
                                    <iframe v-else
                                            class="cookieconsent-optin-marketing object-contain absolute w-full h-full top-0 left-0 object-left"
                                            :data-cookieblock-src="`https://www.youtube.com/embed/${media}?playsinline=1&showinfo=0&rel=0`"
                                            frameborder="0"
                                            data-cookieconsent="marketing"
                                            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen></iframe>
                                    <div v-if="!isEditMode" class="cookieconsent-optout-marketing absolute left-0 top-1/2 w-full -translate-y-1/2 pt-16/9">
                                        <div class="absolute w-full h-full top-0 left-0">
                                            <img v-if="thumbnail" :alt="thumbnailAlt" :src="thumbnail" class="absolute w-full h-full top-0 left-0 object-cover object-center">
                                            <div class="absolute bg-black/40 w-full h-full top-0 left-0"></div>
                                        </div>
                                        <div class="absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
                                            <div class="text-xl lg:text-2xl text-center py-8 lg:py-18">
                                                {{ cookieOverlay }}
                                            </div>
                                        </div>
                                        <div class="absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                            <div class="flex justify-center items-center w-full h-full rounded-full">
                                                <a href="javascript:Cookiebot.renew()" :aria-label="cookieOverlay" class="flex justify-center items-center w-16 h-16 rounded-full border border-white cursor-pointer lg:w-36 lg:h-36">
                                                    <icon name="arrow-right-outline"
                                                          class="w-11 h-11 fill-current text-white" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <img v-else-if="media && active && type === 'image'"
                                 :key="`mobile-img-${uuid}`"
                                 :class="{'opacity-100' : active,
                                       'opacity-0' : !active,
                                       'h-full left-0 w-full object-start top-0': rendition === 'reel',
                                       'object-center place-self-center h-full': rendition === 'landscape',
                                       'object-center width-full h-full place-self-center': rendition === 'portrait'}"
                                 :src="media"
                                 :alt="mediaAlt"
                                 class=" grid object-contain absolute transition-opacity duration-500">
                        </div>
                    </div>
                    <slot />
                </div>
            </collapse-transition>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import { Component, Inject, Prop } from 'vue-property-decorator';
import Icon from './base/Icon.vue';

@Component({
    components: { CollapseTransition, Icon }
})
export default class Case extends Vue {
    @Inject({
        default: () => null
    }) readonly registerCase!: Function;

    @Prop() uuid: string;
    @Prop() linkStyle: string;
    @Prop() rendition: string;
    @Prop() title: string;
    @Prop() media: string;
    @Prop({ default: '' }) mediaAlt: string;
    @Prop() type: string;
    @Prop() overlay: string;
    @Prop() cookieOverlay: string;
    @Prop() linkLabel: string;
    @Prop() linkUrl: string;
    @Prop() text: string;
    @Prop({ default: false }) contrast: boolean;
    @Prop({ default: '' }) group: string;
    @Prop({ default: '' }) thumbnail: string;
    @Prop({ default: '' }) thumbnailAlt: string;
    @Prop({ default: false }) isEditMode: boolean;
    @Prop({ default: false }) sophos: boolean;

    active = false;

    mounted() {
        this.registerCase(this);
    }

    created() {
        this.$root.$on('accordion-open', this.onAccordionOpened);
    }

    beforeDestroy() {
        this.$root.$off('accordion-open', this.onAccordionOpened);
    }

    onAccordionOpened(group) {
        if (this.group === '') {
            return;
        }
        if (this.group === group) {
            this.active = false;
        }
    }

    toggle() {
        if (!this.active) {
            this.$root.$emit('accordion-open', this.group);
        }
        this.active = !this.active;
    }
}
</script>
