<template>
    <div class="h-0.5 opacity-100" :class="contrast ? 'bg-white' : 'bg-black'" :style="percentageCount"></div>
</template>

<script lang="ts">
export default {
    name: 'SwiperProgressBar',
    props: {
        fullTime: {
            type: Number,
            default: 5000,
            required: false
        },
        actualIndex: {
            type: Number,
            required: true
        },
        slide: {
            type: Number,
            required: true
        },
        contrast: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            timerCount: null
        };
    },
    methods: {},
    watch: {
        timerCount: {
            handler(value) {
                if (value < this.fullTime && this.actualIndex === this.slide) {
                    setTimeout(() => {
                        this.timerCount = this.timerCount + 50;
                    }, 50);
                }
            },
            immediate: true // This ensures the watcher is triggered upon creation
        },
        actualIndex() {
            if (this.actualIndex === this.slide) this.timerCount = 0;
        }
    },
    computed: {
        percentageCount() {
            if (this.actualIndex !== this.slide) {
                return { width: '0%' };
            }
            return { width: `${(this.timerCount * 100) / this.fullTime}%` };
        }
    }
};
</script>
