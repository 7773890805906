<template>
    <div />
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
class DeferredScript extends Vue {
    @Prop({ required: true }) scriptUrl: string;
    @Prop({ default: () => [] }) scriptAttributes: { name: string; value: string }[];
    @Prop() scriptId: string;

    mounted() {
        const script = document.createElement('script');
        script.onload = () => {
            this.$emit('load');
        };
        script.setAttribute('src', this.scriptUrl);
        this.scriptAttributes.forEach(attr => {
            script.setAttribute(attr.name, attr.value);
        });
        if (this.scriptId) {
            script.setAttribute('id', this.scriptId);
        }
        this.$el.appendChild(script);
    }
}

export default Vue.component('deferred-script', DeferredScript);

</script>
