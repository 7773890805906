<template>
    <div class="flex fixed right-0 top-1/4 z-30 items-center lg:top-1/2">
        <slot name="toggle" :toggle="toggle" :contrast="isContrast" :hidden="hidden" :open="open"/>
        <div class="flex absolute bottom-0 left-0 flex-col"
             :class="{ 'pointer-events-none': !open, 'lg:-bottom-4': openDown }">
            <slot :get-pos="getPos" />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Icon from './base/Icon.vue';

@Component({
    components: { Icon }
})
export default class FixedScrollButton extends Vue {
    @Prop({ default: 0 }) numItems: number;
    @Prop({ default: false, type: Boolean }) openDown: boolean;
    @Inject({ default: () => ({ contrast: false, element: null }) }) currentSection: {
        contrast: boolean;
        element: HTMLElement;
    };

    hidden = true;
    open = false;
    trigger = null;

    created() {
        gsap.registerPlugin(ScrollTrigger);
    }

    mounted() {
        this.trigger = document.querySelector('[data-trigger=content]');
        if (!this.trigger) {
            // don't initialize scroll trigger if no trigger element is found
            return;
        }
        // show/hide the button if the trigger enters/leaves the screen
        // trigger is the element with the [data-trigger=content] attribute
        // and button is shown as soon as the top of the trigger element scrolls
        // to the middle of the screen
        ScrollTrigger.create({
            trigger: this.trigger,
            start: 'top center',
            toggleActions: 'play reverse play reverse',
            onToggle: scrollTrigger => {
                this.hidden = !scrollTrigger.isActive;
            },
            onRefresh: scrollTrigger => {
                this.hidden = !scrollTrigger.isActive;
            },
            animation: gsap.fromTo(this.$el, { x: '100%' }, { x: 0 })
        });
    }

    /**
     * based on the number of items, calculate the absolute position of each item when expanded
     * @param index index of the current item
     * @param mobile flag to indicate if we're on mobile or not
     */
    getPos(index: number, mobile: boolean) {
        if (this.open) {
            return {
                transform: `translateY(${(4.24 * (this.numItems - index) + 1) * ((mobile || this.openDown) ? 1 : -1)}rem)`,
                opacity: 1
            };
        }
        return {
            opacity: 0
        };
    }

    toggle() {
        this.open = !this.open;
    }

    get isContrast(): boolean {
        return this.currentSection.contrast || false;
    }

    @Watch('hidden')
    onToggleHidden() {
        if (this.hidden) {
            this.open = false;
        }
    }
}
</script>
