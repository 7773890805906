<template>
    <div class="relative mb-4">
        <div v-if="chooseLabel" :class="[form ? 'opacity-70 text-sm' : 'text-xl text-gray-600']" class="mb-2">
            {{ activeTitle ? label : chooseLabel }}
        </div>
        <div class="flex justify-between items-center border-current cursor-pointer"
             @click="open = !open"
             :class="[contrast ? 'border-white text-white' : 'border-black text-black', form ? 'pb-1 border-b' : 'pb-2 border-b-2']">
            <div class="flex items-center">
                <div @click.stop="onRemoveValue" class="w-6 h-6 mr-1" v-if="activeTitle && !form">
                    <icon v-if="activeTitle"
                          name="close"
                          class="w-6 h-6 m-0 fill-current" />
                </div>
                <div :class="[form ? 'text-l' : 'text-xl my-1 leading-tight']">{{ activeTitle || (defaultTitle || label) }}</div>
            </div>
            <icon name="chevron-down"
                  class="w-6 h-6 transition-transform origin-center fill-current"
                  :class="{ 'rotate-180': open }" />
        </div>
        <span v-if="notvalid && !activeValue.length" class="form-validate mt-1">
                {{ validateText }}
            </span>
        <collapse-transition ref="container">
            <div v-show="open"
                 class="overflow-y-auto absolute top-full z-30 w-full border-b scrollbar scrollbar-brighter border-gray-200 max-h-64"
                 :class="[contrast && !form ? 'bg-black text-white' : 'bg-white text-black']">
                <slot />
            </div>
        </collapse-transition>
        <slot name="selection" :value="activeValue" />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Provide, Ref } from 'vue-property-decorator';
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';
import Icon from './base/Icon.vue';
import DropdownItem from './DropdownItem.vue';

@Component({
    components: { Icon, CollapseTransition }
})
class Dropdown extends Vue {
    @Ref('container') container: Vue;
    @Provide('register') childRegistration = this.registerItem;
    @Provide() dropdownItemClicked = this.onItemClicked;
    @Prop({ required: true }) label: string;
    @Prop({ default: '' }) defaultKey: string;
    @Prop({ default: '' }) defaultValue: string;
    @Prop({ default: '' }) defaultTitle: string;
    @Prop({ default: false, type: Boolean }) contrast: boolean;
    @Prop({ default: false, type: Boolean }) required: boolean;
    @Prop({ default: false, type: Boolean }) notvalid: boolean;
    @Prop({ default: false, type: Boolean }) form: boolean;
    @Prop() chooseLabel: string;
    @Prop() validateText: string;

    open = false;
    activeTitle = '';
    activeValue: string | number = '';
    items = [];

    mounted() {
        document.addEventListener('click', this.onOutsideClick);
    }

    beforeDestroy() {
        document.removeEventListener('click', this.onOutsideClick);
    }

    // called by child items
    registerItem(item) {
        item.contrast = this.contrast && !this.form;
        this.items.push(item);
        if (item.active) {
            this.activeValue = item.value;
            this.activeTitle = item.label;
        }
        if (this.container.$children.filter(this.filterItems).length === this.items.length) {
            this.$nextTick(() => {
                if (this.defaultValue && this.items.find(x => x.value === this.defaultValue)) {
                    this.activeValue = this.defaultValue;
                    this.activeTitle = this.defaultTitle || this.defaultValue;
                } else if (this.activeValue !== '' && this.items.every(x => !x.selected)) {
                    if (this.items.length > 0) {
                        this.setValue(this.activeValue, false);
                    } else {
                        this.activeValue = '';
                    }
                }
            });
        }
    }

    resetDropdown() {
        this.activeTitle = '';
        this.activeValue = '';
        this.items.forEach(i => {
            if (i.selected) {
                this.$emit('deselect', i.value);
            }
            i.selected = false;
        });
    }

    // check children to only count slide components
    filterItems(child: Vue) {
        return child.$vnode && child.$vnode.componentOptions && child.$vnode.componentOptions.tag === 'dropdown-item';
    }

    onItemClicked(item: DropdownItem, notclick: boolean) {
        this.items.forEach(i => {
            if (i.selected && i.value !== item.value && !notclick) {
                this.$emit('deselect', i.value);
            }
            i.selected = false;
        });
        if (this.activeTitle === item.label && this.activeValue === item.value) {
            this.activeTitle = this.defaultTitle;
            this.activeValue = this.defaultValue;
        } else {
            this.activeTitle = item.label;
            this.activeValue = item.value;
            item.selected = true;

            if (!notclick) {
                // selected elements via the dataLayer, so that these can then be captured in the GTM
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'dropdownSelection',
                    term: this.defaultKey + ':' + item.value
                });
            }
        }
        this.open = false;
        if (!notclick) {
            this.$emit('select', item.value);
        }
    }

    onRemoveValue() {
        this.items.forEach(i => {
            i.selected = false;
        });
        this.$emit('select', this.activeValue);
        this.activeTitle = '';
        this.activeValue = '';
    }

    onOutsideClick(evt) {
        let targetElement = evt.target;

        do {
            if (targetElement === this.$el) {
                return;
            }
            targetElement = targetElement.parentNode;
        } while (targetElement);
        this.open = false;
    }

    setValue(value, notclick: boolean) {
        if (this.items.length > 0) {
            const item = this.items.find(item => item.value === value);
            if (item) {
                this.onItemClicked(item, notclick);
            } else {
                this.activeValue = '';
                this.activeTitle = '';
            }
        } else {
            this.activeValue = value;
        }
    }
}

export default Dropdown;

</script>
