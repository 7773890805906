/**
 * Fade-in directive
 *
 * Fades in a HTMlElement as it scrolls into view
 * Usage: <div v-fade-in:delay></div> where delay (in ms) is an optional parameter
 * Relies on css classes defined in fade.css
 *
 * @author Julian Frank (julian.frank@lemonize.de)
 */
import { DirectiveBinding } from 'vue/types/options';

function onFadeIn(entries: IntersectionObserverEntry[]) {
    const ratio = 0.01;

    Array.from(entries).forEach(entry => {
        const target = entry.target;

        if (entry.intersectionRatio > ratio) {
            target.classList.add('fade-in:visible');
        } else if (target.classList.contains('fade-out')) {
            target.classList.remove('fade-in:visible');
        }
    });
}

export default {
    bind(element: HTMLElement, binding: DirectiveBinding) {
        element.classList.add((binding.arg ? `${binding.arg}:` : '') + 'fade-in');
        if (binding.value && !isNaN(parseInt(binding.value))) {
            element.style.transitionDelay = `${binding.value}ms`;
        }
        const observer = new IntersectionObserver(onFadeIn, {
            root: null,
            rootMargin: '0px 0px',
            threshold: 0.01
        });
        setTimeout(() => {
            observer.observe(element);
        });
    }
};
