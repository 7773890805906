<template>
    <div class="fixed top-0 left-0 z-10 w-screen h-screen bg-black pointer-events-auto bg-hero"
         :class="{ 'max-h-available': result && result.results.length, 'bg-angle-hero-dark': !isSophos }">
        <div class="flex flex-col pt-32 h-full lg:pt-64">
            <div class="container">
                <div class="flex justify-between items-center border-b border-white">
                    <input
                        class="flex-1 pb-2 text-2xl text-white bg-transparent outline-none placeholder-gray-300 lg:text-5xl"
                        :placeholder="placeholder"
                        ref="input"
                        @input="search"
                        v-model="searchValue">
                    <icon name="search-outline"
                          :class="[searchValue.length ? 'text-white' : 'text-gray-300']"
                          class="shrink-0 w-6 h-6 fill-current lg:transition-colors lg:group-hover:text-black" />
                </div>
            </div>
            <div :class="{ 'tracking-wider uppercase': !isSophos }" class="container mt-20 mb-4"
                 v-if="result && result.total">
                {{ result.total }} {{ resultsLabel }}
            </div>
            <div v-if="error" class="container mt-16">{{ errorMessage }}</div>
            <search-results v-else-if="result" :results="result.results" :no-results-message="noResultsMessage"
                            in-overlay>
                <a class="flex bottom-0 left-0 justify-center py-4 mt-20 w-full bg-berry-600 hover:bg-berry-700"
                   :href="`${searchLink}?query=${sanitizedInput}`"
                   v-if="result.total > result.results.length && searchLink">
                    {{ allResultsLabel }} ({{ result.total }})
                </a>
            </search-results>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import axios from 'axios';
import Icon from './base/Icon.vue';
import { debounce } from 'lodash';
import SearchResults from './SearchResults.vue';
import { SearchResult } from './GftSearch.vue';

const SEARCH_ENDPOINT = '/.rest/api/v1/search';

interface DataLayerItem {
    event: string;

    [key: string]: any;
}

@Component({
    components: { Icon, SearchResults }
})
export default class SearchOverlay extends Vue {
    @Prop({ required: true }) allResultsLabel: string;
    @Prop({ required: true }) resultsLabel: string;
    @Prop({ required: true }) placeholder: string;
    @Prop({ required: true }) searchLink: string;
    @Prop({ required: true }) errorMessage: string;
    @Prop({ required: true }) noResultsMessage: string;

    search = debounce(this.onSearch, 350);
    debouncedTrackSearch = debounce(this.trackSearch, 2000);
    searchValue = '';
    error = false;
    placeholderImage = `${this.$contextPath}/.resources/gft/webresources/img/search-placeholder.webp`;
    result: SearchResult = null;

    async onSearch() {
        if (this.sanitizedInput.length > 2) {
            try {
                this.error = false;
                const response = await axios.get<SearchResult>(`${this.$contextPath}${SEARCH_ENDPOINT}/${this.$site}/${this.$lang}?query=${this.sanitizedInput}&limit=3`);
                if (response.status === 200) {
                    this.result = response.data;
                } else {
                    this.result = null;
                }
                // tracking search
                this.debouncedTrackSearch();
            } catch (e) {
                this.error = true;
            }
        } else {
            this.result = null;
            this.error = false;
        }
    }

    trackSearch() {
        const dataLayer: DataLayerItem[] = (window as any).dataLayer || [];
        if (dataLayer) {
            dataLayer.push({
                event: 'searchEvent',
                // eslint-disable-next-line @typescript-eslint/camelcase
                search_term: this.sanitizedInput
            });
        }
    }

    get sanitizedInput() {
        return this.searchValue.replace(/[^a-zA-Z0-9\s-_.]/g, '').replace(/[-_]/g, ' ').trim();
    }

    get isSophos() {
        return this.$site === 'sophossolutions';
    }
}

</script>
