<template>
    <div class="relative">
        <div ref="container" class="relative w-full lg:min-h-screen">
            <img :src="image" :alt="imageAlt" class="object-cover absolute top-0 left-0 w-full h-full">
            <div ref="svg-container"
                 :class="[contrast ? 'bg-black' : 'bg-white']"
                 class="absolute top-0 left-0 w-full h-full pointer-events-none lg:block">
                <svg class="w-full h-full">
                    <defs>
                        <mask :id="`zoom-mask-${_uid}`" :style="dynamicFontSize">
                            <text ref="text" :fill="mask" :y="`${highlightWordPos}px`" text-anchor="end" x="100%">
                                {{ highlightWord }}
                            </text>
                            <g ref="angle" class="absolute top-0 left-0">
                                <svg :height="`${ANGLE_SIZE}px`"
                                     :width="`${ANGLE_SIZE}px`"
                                     viewBox="0 0 205 205"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <rect id="Rectangle" :fill="mask" height="60" width="205" />
                                    <rect id="Rectangle-2" :fill="mask" data-name="Rectangle" height="205" width="60" />
                                </svg>
                            </g>
                        </mask>
                    </defs>

                    <image :mask="`url(#zoom-mask-${_uid})`"
                           :xlink:href="image"
                           height="100%"
                           preserveAspectRatio="xMinYMid slice"
                           width="100%" />

                    <text ref="preTextContainer"
                          :fill="contrast ? '#fff' : '#111'"
                          class="hidden lg:block"
                          :style="dynamicFontSize"
                          text-anchor="end"
                          y="20%">
                        <tspan ref="firstLine" dy="1.1em" x="100%">{{ firstLine }}</tspan>
                        <tspan v-if="secondLine" ref="secondLine" dy="1.1em" x="100%">{{ secondLine }}</tspan>
                        <tspan v-if="thirdLine" ref="thirdLine" dy="1.1em" x="100%">{{ thirdLine }}</tspan>
                    </text>
                </svg>
            </div>
        </div>
        <div :class="{ 'bg-black text-white': contrast }"
             class="flex flex-col px-9 pt-16 pb-8 lg:pt-32 text-5xl lg:hidden">
            <div>{{ firstLine }}</div>
            <div v-if="secondLine">{{ secondLine }}</div>
            <div v-if="thirdLine">{{ thirdLine }}</div>
            <div :style="`background-image: url(${imageSmall})`" class="relative text-transparent bg-clip-text break-words text-5xl">
                {{ highlightWord }}
            </div>
        </div>
        <div ref="content" class="top-0 left-0 w-full -z-1" :class="pinSpacing ? 'lg:h-full' : 'lg:h-screen'">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Ref } from 'vue-property-decorator';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { getTwConfig } from '../utils';

@Component
class ZoomText extends Vue {
    @Ref('container') container;
    @Ref('svg-container') svgContainer;
    @Ref('text') textContainer;
    @Ref('angle') angle;
    @Ref('preTextContainer') preTextContainer;
    @Ref('content') content;
    @Ref('firstLine') firstLineEl: SVGTSpanElement;
    @Ref('secondLine') secondLineEl: SVGTSpanElement;
    @Ref('thirdLine') thirdLineEl: SVGTSpanElement;
    @Prop({ required: true }) image: string;
    @Prop({ default: 'zoom-image' }) imageAlt: string;
    @Prop({ required: true }) imageSmall: string;
    @Prop({ required: true }) text: string;
    @Prop({ default: false }) contrast: false;
    @Prop({ default: false }) pinSpacing: false;

    mask = '#eee';
    twConfig = getTwConfig();
    highlightWordPos = 0;
    resizeTimer: NodeJS.Timeout = null;
    ANGLE_SIZE = 100;
    windowWidth = 0;

    get preText(): string[] {
        if (this.text.trim().split(' ').length > 1) {
            return this.text.trim().split(' ').slice(0, -1);
        }
        return [];
    }

    get numLines(): number {
        return this.preText.join('').length > 40 ? 3 : this.preText.join('').length > 20 ? 2 : 1;
    }

    get firstLine(): string {
        if (this.preText.length) {
            return this.preText.slice(0, Math.ceil(this.preText.length / this.numLines)).join(' ');
        }
        return '';
    }

    get secondLine(): string {
        if (this.numLines < 2) {
            return null;
        }
        if (this.preText.length) {
            return this.preText.slice(Math.ceil(this.preText.length / this.numLines), this.numLines > 2 ? 2 * Math.ceil(this.preText.length / this.numLines) : this.preText.length).join(' ');
        }
        return '';
    }

    get thirdLine(): string {
        if (this.numLines < 3) {
            return null;
        }
        if (this.preText.length) {
            return this.preText.slice(this.firstLine.split(' ').length + this.secondLine.split(' ').length).join(' ');
        }
        return '';
    }

    get highlightWord() {
        if (this.text.trim().split(' ').length > 1) {
            return this.text.trim().split(' ').pop();
        }
        return this.text;
    }

    // calculate font size based on width and text lenght
    get dynamicFontSize() {
        const maxFontSizeSmallScreen = 3;
        const maxFontSizeLargeScreen = 9;
        const characterLenght = 9;

        const isLargeScreen = this.windowWidth >= 1024;
        const maxFontSize = isLargeScreen ? maxFontSizeLargeScreen : maxFontSizeSmallScreen;

        const firstLineLength = this.firstLine.trim().length;
        const secondLineLength = this.secondLine ? this.secondLine.trim().length : 0;
        const thirdLineLength = this.thirdLine ? this.thirdLine.trim().length : 0;

        const textLength = Math.max(firstLineLength, secondLineLength, thirdLineLength);
        const estimatedTextWidth = textLength * (maxFontSize * characterLenght);

        if (estimatedTextWidth > this.windowWidth) {
            const fontSize = this.windowWidth / (textLength * characterLenght);
            return {
                fontSize: `${Math.min(fontSize, maxFontSize)}rem`,
                lineHeight: `${Math.min(fontSize, maxFontSize) * 1.5}rem`
            };
        }

        return {
            fontSize: `${maxFontSize}rem`,
            lineHeight: `${maxFontSize * 1.5}rem`
        };
    }

    created() {
        gsap.registerPlugin(ScrollTrigger);
        this.updateWindowWidth();
    }

    mounted() {
        this.initScrollTrigger();
        window.addEventListener('resize', this.updateWindowWidth);
    }

    beforeDestroy() {
        window.removeEventListener('resize', this.updateWindowWidth);
    }

    updateWindowWidth() {
        this.windowWidth = window.innerWidth;
    }

    initScrollTrigger() {
        // skip on mobile
        if (getComputedStyle(this.firstLineEl.parentElement).display === 'none') {
            return;
        }

        // workaround for chrome (doesnt render mask otherwise)
        setTimeout(() => {
            this.mask = '#fff';
        }, 300);

        if (this.thirdLineEl) {
            this.highlightWordPos = this.thirdLineEl.getStartPositionOfChar(0).y + (this.secondLineEl.getStartPositionOfChar(0).y - this.firstLineEl.getStartPositionOfChar(0).y);
        } else if (this.secondLineEl) {
            this.highlightWordPos = this.secondLineEl.getStartPositionOfChar(0).y + (this.secondLineEl.getStartPositionOfChar(0).y - this.firstLineEl.getStartPositionOfChar(0).y);
        } else if (this.firstLineEl && this.firstLineEl.getNumberOfChars() > 0) {
            this.highlightWordPos = this.firstLineEl.getStartPositionOfChar(0).y + (this.firstLineEl.getBBox().height * 1.1);
        } else {
            this.highlightWordPos = 500;
        }

        ScrollTrigger.matchMedia({
            // desktop
            [`(min-width: ${this.twConfig.theme.screens.lg})`]: () => {
                const tl = gsap.timeline({
                    scrollTrigger: {
                        start: 'top top',
                        end: '+=100%',
                        trigger: this.container,
                        scrub: true,
                        pin: true,
                        pinSpacing: this.pinSpacing,
                        onLeave: () => {
                            if (this.pinSpacing) {
                                window.scrollTo({ top: window.scrollY + 175, behavior: 'smooth' });
                            }
                        }
                    }
                });
                const xOffset = Math.max(36, (innerWidth - 1624) / 2);
                gsap.set(this.textContainer, {
                    x: `-=${xOffset}`,
                    svgOrigin: `${window.innerWidth - 5} ${this.highlightWordPos - 20}`
                });
                gsap.set(this.angle, {
                    x: window.innerWidth - xOffset - this.ANGLE_SIZE - 10,
                    y: this.highlightWordPos + 90
                });
                gsap.set(this.angle, {
                    transformOrigin: '25% 40%'
                });
                gsap.set(this.preTextContainer, { x: `-=${xOffset}` });
                gsap.set(this.content, { opacity: 0 });
                tl
                    .to(this.angle, {
                        x: window.innerWidth / 3 * 2,
                        y: window.innerHeight / 2,
                        scale: 120,
                        duration: 10,
                        ease: 'power3.in',
                        onUpdate: () => {
                            this.mask = this.mask === '#fff' ? '#ffe' : '#fff';
                        }
                    })
                    .to(this.textContainer, {
                        opacity: 0,
                        y: '-200%',
                        delay: 3
                    }, 0)
                    .to(this.preTextContainer, { opacity: 0, y: '-200%', delay: 3 }, 0)
                    .to(this.svgContainer, { opacity: 0, duration: 1 })
                    .to(this.content, { opacity: 1 }, 3);
            }
        });
    }
}

export default Vue.component('zoom-text', ZoomText);

</script>
