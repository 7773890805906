<template>
    <div class="py-3.5 px-4 text-lg transition-colors cursor-pointer flex justify-between items-center"
         v-show="visible"
         :class="[selected ? (contrast ? 'bg-gray-800' : 'text-white bg-black') : (contrast ? 'text-white' : 'text-black'), contrast ? 'hover:bg-gray-800' : 'hover:bg-gray-200 hover:text-black', { 'text-gray-600 pointer-events-none': disabled && !selected }]"
         @click="onClick">
        <span>{{ label }}</span>
        <icon v-if="selected && cancelable" name="close" class="w-6 h-6 fill-current" />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Inject } from 'vue-property-decorator';
import Icon from './base/Icon.vue';

@Component({
    components: { Icon }
})
export default class DropdownItem extends Vue {
    @Prop() label: string;
    @Prop({ default: '' }) value: string | number;
    @Prop({ default: false, type: Boolean }) disabled: boolean;
    @Prop({ default: false, type: Boolean }) cancelable: boolean;
    @Prop({ default: true, type: Boolean }) visible: boolean;
    @Prop({ default: false, type: Boolean }) active!: boolean;
    @Inject({
        default: () => null
    }) readonly register!: Function;

    @Inject({
        default: () => null
    }) readonly dropdownItemClicked!: Function;

    contrast = false;
    selected = false;

    mounted() {
        // register self on parent component
        this.register(this);
        this.selected = this.active;
    }

    onClick() {
        if (this.disabled && !this.selected) {
            return;
        }
        this.dropdownItemClicked(this);
    }
}
</script>
