<template>
    <div>
        <div class="line-clamp-4 overflow-hidden" v-show="!visible">
            <slot />
        </div>
        <button @click="toggle" :class="[{'hidden' : visible}]" class="pt-4">
            <icon name="arrow"
                  class="inline-block w-4 h-4 align-middle rotate-90 fill-current" />
            <span class="text-current">
               {{ clamp }}
            </span>
        </button>
        <collapse-transition v-show="visible" :duration="0">
            <div>
                <slot />
                <button @click="toggle" class="pt-4">
                    <icon name="arrow"
                          class="inline-block w-4 h-4 align-middle -rotate-90 fill-current" />
                    <span class="text-current" :class="{'hidden' : !visible}">
                       {{ less }}
                    </span>
                </button>
            </div>
        </collapse-transition>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';

import Icon from './base/Icon.vue';

@Component({
    components: { Icon, CollapseTransition }
})
class Readmore extends Vue {
    @Prop() text: string;
    @Prop() clamp: string;
    @Prop() less: string;
    @Prop({ default: false }) open!: boolean;

    visible = false;

    mounted() {
        this.visible = this.open;
    }

    toggle() {
        this.visible = !this.visible;
    }
}

export default Vue.component('read-more', Readmore);

</script>
