import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import Vue, { VNode, VNodeData } from 'vue/types/umd';
import dayjs from 'dayjs';

export function getTwConfig() {
    return resolveConfig(tailwindConfig);
}
function easeOut(n) {
    return n * (2 - n);
}

function tweenUpdate(prop, from, to, duration, startTime) {
    const now = Date.now();
    const progress = (now - startTime) / duration;
    Object.keys(to).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(prop, key)) {
            prop[key] = from[key] + (to[key] - from[key]) * easeOut(progress);
        }
    });
    if (progress < 1) {
        requestAnimationFrame(() => {
            tweenUpdate(prop, from, to, duration, startTime);
        });
    } else {
        Object.keys(to).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(prop, key)) {
                prop[key] = from[key] + (to[key] - from[key]);
            }
        });
    }
}

/**
 * animate a property over a specified duration
 * @param prop object to animate
 * @param duration animation duration
 * @param to object with target properties
 */
export function tween(prop, duration, to): void {
    tweenUpdate(prop, Object.keys(to).reduce((from, key) => {
        if (Object.prototype.hasOwnProperty.call(prop, key)) {
            from[key] = prop[key];
            return from;
        }
    }, {}), to, duration * 1000, Date.now());
}

/**
 * returns an object of all breakpoints from the tailwind config
 */
export function breakpoints(): object {
    const screens = getTwConfig().theme.screens;
    return Object.keys(screens).reduce((obj, key) => {
        obj[key] = parseInt(screens[key]);
        return obj;
    }, {});
    return {};
}

/**
 * gets the name of current breakpoint according to the tailwind config
 */
export function currentBreakpoint(): string {
    const screenWidth = window.innerWidth;
    for (const [key, value] of Object.entries(breakpoints())) {
        if (screenWidth < value) return key;
    }
    return Object.keys(breakpoints()).pop();
}

/**
 * returns a negative value if a is a smaller breakpoint than b
 * and a positive value if a is a larger breakpoint than b
 * return 0 if a and b are equal or NaN if an invalid breakpoint name was provided
 * @param a the first breakpoint name to compare
 * @param b the second breakpoint name to compare
 */
export function compareBreakpoints(a, b): number {
    const screens = breakpoints();
    if (Object.prototype.hasOwnProperty.call(screens, a) && Object.prototype.hasOwnProperty.call(screens, b)) {
        return screens[a] - screens[b];
    }
    return NaN;
}

export function vnodeToRenderObject(vnode: VNode): VNodeData {
    const obj: VNodeData = {};
    if (vnode.data) {
        obj.class = vnode.data.class;
        obj.style = vnode.data.style;
        obj.attrs = vnode.data.attrs;
        if (vnode.data.staticClass) {
            if (obj.class) {
                obj.class.push(...vnode.data.staticClass.split(' '));
            } else {
                obj.class = vnode.data.staticClass.split(' ');
            }
        }
    }
    if (vnode.componentOptions) {
        obj.props = vnode.componentOptions.propsData;
    }
    return pickBy(obj, identity);
}

/**
 * to be used in vue render functions to render the slot children without
 * a wrapper (if only one child is present or conditionally render a wrapper
 * if multiple children are present.
 * @param vnode Vue component to which the slot belongs (usually {this}
 * @param createElement the createElement (or h) function passed to the vue render function
 * @param classes (optional) an array of css classes to add to the child/wrapper
 * @param style (optional) optional style binding for the wrapper
 * @param wrapperTag (optional) if multiple children are inside the slot a wrapper element will be created. default: div
 * @param slot (optional) the name of the slot (default: default)
 */
export function renderSlotOrWrapper(vnode: Vue, createElement: Function, options, wrapperTag = 'div', slot = 'default') {
    if (vnode.$slots[slot] && vnode.$slots[slot].length === 1) {
        const el = vnode.$slots[slot][0];
        const _class = options.class || [];
        if (options.attrs && options.attrs.class) {
            _class.push(...options.attrs.class.split(' '));
        }
        const opts = vnodeToRenderObject(el);
        const _options = cloneDeep(opts);
        merge(_options, options);
        _options.class = _class.concat(...(opts.class || []));
        return createElement(el.componentOptions ? el.componentOptions.tag : el.tag, _options, el.children);
    } else {
        return createElement(wrapperTag, options, vnode.$slots.default);
    }
}

// Determine if an element is an HTML element
export const isElement = el => !!(el && el.nodeType === Node.ELEMENT_NODE);

// Add a class to an element
export const addClass = (el, className) => {
    // We are checking for `el.classList` existence here since IE 11
    // returns `undefined` for some elements (e.g. SVG elements)
    if (className && isElement(el) && el.classList) {
        el.classList.add(className);
    }
};

// Remove a class from an element
export const removeClass = (el, className) => {
    // We are checking for `el.classList` existence here since IE 11
    // returns `undefined` for some elements (e.g. SVG elements)
    if (className && isElement(el) && el.classList) {
        el.classList.remove(className);
    }
};

// Test if an element has a class
export const hasClass = (el, className) => {
    // We are checking for `el.classList` existence here since IE 11
    // returns `undefined` for some elements (e.g. SVG elements)
    if (className && isElement(el) && el.classList) {
        return el.classList.contains(className);
    }
    return false;
};

// Get computed style object for an element
export const getCS = el => {
    const { getComputedStyle } = window;
    return getComputedStyle && isElement(el) ? getComputedStyle(el) : {};
};

// Determine if an element matches a selector
// eslint-disable-next-line dot-notation
export const matches = (el, selector) => (isElement(el) ? (Element.prototype.matches || Element.prototype['msMatchesSelector'] || Element.prototype.webkitMatchesSelector).call(el, selector) : false);

export const closestEl =
    Element.prototype.closest ||
    function(sel) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let el = this;
        do {
            // Use our "patched" matches function
            if (matches(el, sel)) {
                return el;
            }
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === Node.ELEMENT_NODE);
        return null;
    };

// Finds closest element matching selector. Returns `null` if not found
export const closest = (selector, root, includeRoot = false) => {
    if (!isElement(root)) {
        return null;
    }
    const el = closestEl.call(root, selector);

    // Native closest behaviour when `includeRoot` is truthy,
    // else emulate jQuery closest and return `null` if match is
    // the passed in root element when `includeRoot` is false
    return includeRoot ? el : el === root ? null : el;
};

// Return the Bounding Client Rect of an element
// Returns `null` if not an element
export const getBCR = el => (isElement(el) ? el.getBoundingClientRect() : null);

// Cause/wait-for an element to reflow its content (adjusting its height/width)
export const reflow = el => {
    // Requesting an elements offsetHeight will trigger a reflow of the element content
    return isElement(el) && el.offsetHeight;
};

// Remove an style property from an element
export const removeStyle = (el, prop) => {
    if (prop && isElement(el)) {
        el.style[prop] = '';
    }
};

// Set an style property on an element
export const setStyle = (el, prop, value) => {
    if (prop && isElement(el)) {
        el.style[prop] = value;
    }
};

const getState = (): string[] => {
    return location.hash.replace(/^#|\W$/, '').split('&').filter(x => x.length);
};

const setState = (state: string[]) => {
    window.history.replaceState({}, null, `#${state.join('&')}`);
};

export const addToState = (key: string, value: string) => {
    const state = getState();
    const index = state.findIndex(x => x.split(':').length > 0 && x.split(':')[0] === key);
    if (index < 0) {
        state.push(`${key}:${value}`);
    } else {
        const values = state[index].split(':')[1].split(',');
        if (!values.includes(value)) {
            values.push(value);
            state[index] = `${key}:${values.join(',')}`;
        }
    }
    setState(state);
};

export const removeFromState = (key: string, value = '') => {
    const state = getState();
    const index = state.findIndex(x => x.split(':').length > 0 && x.split(':')[0] === key);
    if (index >= 0) {
        if (value.length) {
            const values = state[index].replace(/[\w-]+:/, '').split(',');
            const valueIndex = values.findIndex(x => x === value);
            if (valueIndex >= 0) {
                values.splice(valueIndex, 1);
                if (values.length > 0) {
                    state[index] = `${key}:${values.join(',')}`;
                } else {
                    state.splice(index, 1);
                }
            }
        } else {
            state.splice(index, 1);
        }
        setState(state);
    }
};

export const formatDate = (date: string, sourceFormat = 'YYYY-MM-DD'): string => {
    // eslint-disable-next-line dot-notation
    const lang = window['lang'];

    let targetFormat = 'DD MMMM YYYY';
    switch (lang) {
        case 'pl':
        case 'de':
            targetFormat = 'DD. MMMM YYYY';
            break;
        case 'en_US':
            targetFormat = 'MMMM DD, YYYY';
            break;
        default:
            break;
    }
    return dayjs(date, sourceFormat).format(targetFormat);
};
