/* eslint-disable dot-notation */
import Vue from 'vue';
import Outline from '@lemonize/outlining';
import VueSmoothScroll from 'vue2-smooth-scroll';
import store from './store';
import './component-loader';
import FadeIn from './directives/fade-in';
import InView from './directives/in-view';
import Lightbox from './directives/lightbox';
import AsyncForm from './directives/async-form';
import SameSize from './directives/same-size';
import Animate from './directives/animate';
import ReadTime from './directives/read-time';
import ScrollBorder from './directives/scroll-border';
import { formatDate } from './components/utils';
import { SectionObserver } from './libs/SectionObserver';
import Footnote from './directives/footnote';

/* Directives */
Vue.directive('fade-in', FadeIn);
Vue.directive('in-view', InView);
Vue.directive('lightbox', Lightbox);
Vue.directive('async-form', AsyncForm);
Vue.directive('same-size', SameSize);
Vue.directive('animate', Animate);
Vue.directive('read-time', ReadTime);
Vue.directive('scroll-border', ScrollBorder);
Vue.directive('footnote', Footnote);

/* Plugins */
Vue.use(Outline);
Vue.use(VueSmoothScroll);

/* Filter */
// global truncate filter
// truncates text to the given length optionally ignoring html tags
Vue.filter('truncate', (val, len = 65, ignoreHTML = false) => {
    if (val && val.length > len) {
        let length = len;
        if (ignoreHTML) {
            const tmp = document.createElement('DIV');
            tmp.innerHTML = val;
            const textOnlyLength = (tmp.textContent || tmp.innerText || val).length;
            if (textOnlyLength <= len) {
                return val;
            }
            length += (val.length - textOnlyLength);
        }
        return `${val.slice(0, length)}...`;
    }
    return val;
});

Vue.filter('date', (date: string) => formatDate(date));

// define context- and resource path globally for vue components
Vue.prototype.$contextPath = window['contextPath'];
Vue.prototype.$resourcePath = window['resourcePath'];
Vue.prototype.$site = window['siteName'];
Vue.prototype.$lang = window['lang'];
Vue.prototype.$prototype = window['prototype'];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const app = new Vue({
    el: '#app',
    store,
    comments: true,
    mixins: [SectionObserver],
    mounted() {
        this.initSectionObserver();
        this.scrollToAnchor();
        this.getScrollBarWidth();
        window.addEventListener('resize', this.getScrollBarWidth);
        window.addEventListener('hashchange', this.scrollToAnchor);
    },

    methods: {
        // calculate the width of the scrollbar
        getScrollBarWidth(): void {
            document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + 'px');
        },
        // manually scroll to anchor links, since hash can also contain other state info
        scrollToAnchor(): void {
            if (location.hash && location.hash.length) {
                location.href = location.hash;
                const hash = location.hash.replace(/^#/, '').split('&');
                if (hash.length > 0) {
                    const el = document.getElementById(hash[0]);
                    if (el) {
                        setTimeout(() => {
                            this.$smoothScroll({
                                scrollTo: el,
                                offset: -100,
                                updateHistory: false
                            });
                        }, 500);
                    }
                }
            }
        }
    }
});

// declare types for global variables or prod build will fail
declare module 'vue/types/vue' {
    interface Vue {
        $contextPath: string;
        $resourcePath: string;
        $site: string;
        $lang: string;
        $prototype: boolean;
        _uid: number;
    }
}

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataLayer: any[];
    }
}
