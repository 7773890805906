<template>
    <div class="hidden overflow-hidden absolute right-0 left-0 top-full py-9 w-full -translate-y-full lg:block lg:pb-20">
        <div :class="[contrast ? 'from-black' : 'from-white']"
             class="absolute top-0 left-0 w-full h-full bg-gradient-to-t"></div>
        <div class="container">
            <div class="hidden relative divide-x w-4/5 -ml-12"
                 :class="[contrast ? 'divide-white' : 'divide-black', slides.length >= 3 ? 'hmd:grid grid-cols-3' : (slides.length === 2 ? 'lg:grid grid-cols-2' : 'hmd:flex')]">
                <div v-for="(slide, i) in thumbs" :key="`thumb#${slide.image}:${i}`"
                     class="px-12 leading-5 opacity-70 cursor-pointer hover:opacity-100"
                     @click="$emit('thumb', slides.findIndex(x => x.title === slide.title))">
                    <div>0{{ slides.findIndex(x => x.title === slide.title) + 1 }}</div>
                    <div class="mb-4 w-full text-lg leading-6 line-clamp-3 lg:mb-0" v-html="slide.title">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
class GftSwiperThumbs extends Vue {
    @Prop() index: number;
    @Prop({ default: false }) contrast: boolean;
    @Prop() slides: { title: string; text: string; image: string }[];

    imageLoaded = false;

    get image() {
        if (this.slides.length > this.index) {
            return this.$contextPath + this.slides[this.index].image;
        }
        return '';
    }

    get thumbs(): { title: string; text: string; image: string }[] {
        const thumbs = [];
        const startIndex = this.index + 1;
        const endIndex = this.slides.length > 2 ? this.index + 4 : this.index + 1 + this.slides.length;
        for (let i = startIndex; i < endIndex; i++) {
            thumbs.push(this.slides[i % this.slides.length]);
        }
        return thumbs;
    }

    @Watch('index')
    onIndexChange() {
        this.imageLoaded = false;
    }
}

export default Vue.component('gft-swiper-thumbs', GftSwiperThumbs);

</script>
