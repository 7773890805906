<template>
    <section class="md:border-b-4 md:border-gray-100 md:sticky md:-top-px md:z-30 md:transition-transform md:duration-500"
             :class="{ 'md:-translate-y-full': fix,
             'md:bg-black': styleContentNavigation === 'contrast',
             'md:bg-gray-100': styleContentNavigation === 'semi',
             'md:bg-white': styleContentNavigation === 'default' }">
        <div class="hidden md:block ">
            <div ref="scrollContainer"
                 class="flex overflow-x-auto pt-6 pb-5 w-full text-xl container-wide scrollbar-hide">
                <slot />
            </div>
            <div :style="progressBarStyle" class="absolute self-end h-1 bg-berry-600"></div>
        </div>
        <slot name="mobile" />
    </section>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Provide, Ref } from 'vue-property-decorator';

@Component
class ContentNavigation extends Vue {
    @Prop({ default: 'default' }) styleContentNavigation: string;
    @Provide('registerNavItem') childItemRegistration = this.registerNavItems;
    @Ref('scrollContainer') scrollContainer: HTMLElement;
    scrollPos = 0;
    arrNavItems = [];
    arrSectionElements = [];
    startNav = 0;
    lastScrollPos = 0;
    fix = false;
    sticky = false;
    isScrolling = false;

    mounted() {
        document.addEventListener('scroll', this.onScroll);
        this.startNav = (this.$el as HTMLElement).offsetTop;
        const observer = new IntersectionObserver(
            () => {
                this.sticky = !this.sticky;
            },
            { threshold: [1] }
        );

        observer.observe(this.$el);
    }

    beforeDestroy() {
        document.removeEventListener('scroll', this.onScroll);
    }

    onScroll() {
        this.scrollPos = (Math.max(window.scrollY - this.startNav, 0) / ((document.scrollingElement.scrollHeight - window.innerHeight) - this.startNav)) * 100;
        if (this.isScrolling) {
            return;
        }
        this.verticalScroll();
        const scrollPos = document.scrollingElement.scrollTop;
        this.fix = scrollPos < this.lastScrollPos && (this.$el as HTMLElement).offsetTop < scrollPos;
        this.lastScrollPos = scrollPos;
    }

    registerNavItems(navItem) {
        navItem.$on('activated', ({ offset, clicked }) => {
            if (clicked) {
                this.scrollTo(offset);
            }
            this.horizontalScroll();
        });
        this.arrNavItems.push(navItem);
        if (this.$children.filter(this.filterNavItems).length === this.arrNavItems.length) {
            this.arrSectionElements = this.arrNavItems.map(item => {
                const el = document.getElementById(item.anchor);
                return { component: item, el };
            });
            // anchor in url? -> initial scroll
            if (location.hash) {
                const item = this.arrSectionElements.find(x => x.component.anchor === location.hash.replace('#', ''));
                if (item) {
                    item.component.active = true;
                    setTimeout(() => {
                        this.scrollTo(item.el.offsetTop);
                    }, 200);
                }
            }
        }
    }

    scrollTo(pos) {
        // disable onscroll callback for 1sec (there is no scrollTo end event)
        this.isScrolling = true;
        window.scrollTo({ top: pos - this.$el.clientHeight, behavior: 'smooth' });
        setTimeout(() => {
            this.isScrolling = false;
        }, 1000);
    }

    horizontalScroll() {
        const index = this.arrSectionElements.findIndex(x => x.component.active);
        this.scrollContainer.scrollLeft = index / this.arrSectionElements.length * this.scrollContainer.scrollWidth;
    }

    verticalScroll() {
        if (window.scrollY >= this.startNav) {
            for (let i = 0; i < this.arrSectionElements.length; i++) {
                if ((this.arrSectionElements[i].el.offsetTop - this.$el.clientHeight) <= window.scrollY) {
                    this.arrNavItems.forEach(item => {
                        item.active = false;
                    });
                    this.arrSectionElements[i].component.active = true;
                }
            }
        } else {
            this.arrSectionElements.forEach(item => {
                item.component.active = false;
            });
        }
        if (!this.arrSectionElements.some(x => x.component.active) && this.scrollPos > 0) {
            this.arrSectionElements[0].component.active = true;
        }
    }

    get progressBarStyle() {
        return `width:${this.scrollPos}%`;
    }

    filterNavItems(child: Vue) {
        return child.$vnode && child.$vnode.componentOptions && child.$vnode.componentOptions.tag === 'content-navigation-item';
    }
}

export default Vue.component('content-navigation', ContentNavigation);

</script>
