<template>
    <div>{{ name }}</div>
</template>

<script lang="ts">

import Vue from 'vue';
import { Component, Inject, Prop } from 'vue-property-decorator';

@Component
export default class OverviewCategory extends Vue {
    @Prop() name: string;
    @Prop() categoryId: string;
    @Prop() categoryKey: string;

    active = false;

    @Inject() readonly registerOverviewCategory!: Function;

    mounted() {
        this.registerOverviewCategory(this);
    }
}
</script>
