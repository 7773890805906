<template>
    <div
        :class="{ 'hmax:mb-4 py-4 lg:flex-row hmax:py-0': level === 1, 'lg:mb-8': level === 1 && !sophos, 'py-3': level > 1 && !(open || type === 'separator'), 'hidden lg:flex': !show && level < 2, 'py-2': open, 'pt-3 pb-1': type === 'separator', 'mt-2 lg:-mt-10': type === 'separator' && index === 0, 'mt-8': type === 'separator' && index > 0 }"
        class="flex flex-col justify-between">
        <div :class="[{'text-berry-600': open && level === 1, 'lg:pr-12 lg:items-start lg:w-2/3': level === 1, 'lg:flex-row': level < 2, 'lg:flex-row-reverse lg:justify-end lg:text-berry-600': (open && level === 2), 'lg:transition-transform lg:-translate-x-double-full': (!show || open) && level === 2},
                open && level < 2 ? 'flex-row-reverse justify-end lg:justify-between lg:pl-8' : 'justify-between']"
             class="flex items-center w-full cursor-pointer lg:transition-all group lg:duration-300"
             @click.capture="onClick">
            <template v-if="items.length">
                <!-- mobile -->
                <span :class="{'text-3xl hlg:text-50': level === 1 && !open && !sophos, 'text-lg hlg:text-3xl': level === 1 && !open && sophos, 'text-xl hlg:text-50': level === 1 && open, 'lg:text-gray-300': level === 1 && !show, 'text-xl lg:text-2xl': level === 2, 'hidden': (open && level !== 2) }"
                      class="lg:hidden lg:transition-colors lg:group-hover:text-berry-600">
                    {{ label }}</span>
                <!-- desktop -->
                <span :class="{ 'lg:text-50': level === 1 && !sophos, 'text-lg hlg:text-3xl': level === 1 && sophos, 'lg:text-gray-300': level === 1 && !show, 'lg:text-2xl': level === 2 && !sophos, 'text-md lg:text-lg': level === 2 && sophos, 'text-gray-300 lg:text-white lg:text-2xl': level === 3 }"
                      class="hidden lg:inline lg:transition-colors lg:group-hover:text-berry-600">
                    {{ label }}</span>
            </template>
            <div v-else-if="type === 'separator'"
                 class="w-full text-base uppercase wider text-gray-600 border-b tracking-wider border-gray-400 pb-2">
                {{ label }}
            </div>
            <a v-else
               :class="[{ 'text-3xl hlg:text-50': level === 1 && !sophos, 'text-lg hlg:text-3xl': level === 1 && sophos, 'text-xl lg:text-2xl': level === 2 && !sophos, 'text-md lg:text-lg': level === 2 && sophos, 'text-gray-300 lg:text-white lg:text-2xl': level === 3, 'lg:text-gray-300': level === 1 && !show }, show ? 'lg:group-hover:text-berry-600' : 'lg:group-hover:text-white']"
               :href="link"
               :aria-label="ariaLabel + ' '+ label"
               @click.prevent="onLinkClicked"
               :target="target"
               class="lg:transition-colors">
                {{ label }}
            </a>
            <!-- icon -->
            <icon v-if="level < 3 && items.length"
                  :class="{ '-ml-3 lg:rotate-180': open && level !== 2, 'hidden lg:block': open && level === 1, '-mr-3': !open && level === 1, 'lg:text-gray-300': level === 1 && !show, 'lg:-rotate-90': !open && level === 2, 'lg:hidden': open && level === 2 }"
                  :name="level === 1 ? (open ? 'chevron-left' : 'chevron-right') : open ? 'chevron-up': 'chevron-down'"
                  class="w-10 h-10 fill-current lg:group-hover:text-berry-600" />
        </div>
        <collapse-transition :name="level > 1 ? 'collapse' : 'fade'">
            <div v-show="open"
                 :class="{ 'lg:mt-12 lg:absolute lg:top-0':level == 2, 'lg:transition-transform lg:translate-x-full': toClose || !open}"
                 class="lg:w-full lg:flex-1">
                <div
                    :class="[{ 'text-berry-600 lg:pr-12 lg:items-start lg:w-2/3': level === 1, 'lg:flex-row': level < 2, 'lg:flex-row-reverse lg:justify-end lg:text-berry-600': (open && level === 2) }, level < 2 ? 'flex-row-reverse justify-end lg:justify-between lg:pl-8' : 'justify-between']"
                    class="flex items-center w-full cursor-pointer lg:transition-all group lg:duration-300"
                    @click.capture="onClick">
                    <template v-if="items.length">
                        <!-- mobile -->
                        <span :class="{'text-xl hlg:text-50': level === 1 && !sophos, 'text-lg hlg:text-50': level === 1 && sophos, 'text-xl lg:text-2xl': level === 2 && !sophos, 'text-lg lg:text-2xl': level === 2 && sophos, 'lg:text-gray-300': level === 1 && !show, 'hidden': !((level !== 2))}"
                              class="lg:hidden lg:transition-colors lg:group-hover:text-berry-600">
                            {{ backLabel }}</span>
                        <!-- desktop -->
                        <span :class="{ 'lg:text-50': level === 1 && !sophos, 'lg:text-3xl': level === 1 && sophos, 'lg:text-gray-300': level === 1 && !show, 'lg:text-2xl': level === 2, 'text-gray-300 lg:text-white lg:text-2xl': level === 3, 'lg:hidden': !((level > 1)) }"
                              class="hidden lg:inline lg:transition-colors lg:group-hover:text-berry-600">
                            {{ backLabel }}</span>
                    </template>
                    <!-- icon -->
                    <icon
                        class="-ml-3 w-10 h-10 fill-current hidden lg:group-hover:text-berry-600"
                        :class="{ 'lg:block': level !== 1 }"
                        name="chevron-left" />
                </div>
                <slot />
            </div>
        </collapse-transition>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Inject, Prop, Provide } from 'vue-property-decorator';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import Icon from './base/Icon.vue';
import MainNavigation from './MainNavigation.vue';

@Component({
    components: { Icon, CollapseTransition }
})
export default class MainNavItem extends Vue {
    @Provide('registerMainNavSubItem') childRegistration = this.register;
    @Prop({ required: true }) link: string;
    @Prop({ required: true }) index: number;
    @Prop({ default: '_self' }) target: string;
    @Prop({ required: true }) label: string;
    @Prop({ default: 'nav-item' }) type: string;
    @Prop({ required: true }) backLabel: string;
    @Prop({ required: true }) level: number;
    @Prop({ default: false, type: Boolean }) sophos: boolean;
    @Prop({ default: '' }) ariaLabel: string;

    @Inject({
        default: () => null
    }) readonly registerMainNavItem!: Function;

    items = [];
    open = false;
    toClose = false;
    show = true;

    mounted() {
        // if the parent is also a nav-item register it there, otherwise use injected register method (form main navigation)
        if (this.$parent.$options.name === this.$options.name) {
            (this.$parent as MainNavItem).register(this);
        } else if (this.$parent.$parent.$options.name === this.$options.name) {
            // use $parent.$parent because the transition component is in-between
            (this.$parent.$parent as MainNavItem).register(this);
        } else if (this.$parent.$options.name === MainNavigation.name || this.$parent.$parent.$options.name === MainNavigation.name) {
            this.registerMainNavItem(this);
        }
    }

    onLinkClicked() {
        this.$emit('close');
    }

    onClick() {
        // final link
        if (!this.items.length) {
            location.href = this.link;
        } else {
            this.open = !this.open;
        }

        if (!this.open || this.toClose) {
            this.close();
            this.$emit('click', this);
            this.$emit('open', this.level);
        } else {
            this.items.forEach(item => {
                item.show = true;
                item.close();
            });
            this.$emit('open', this.level + 1);
            this.$emit('click', this);
        }
    }

    close() {
        if (!this.open || this.toClose) {
            return;
        }
        this.open = false;
        this.items.forEach(item => {
            item.close();
        });
    }

    closeAndHide() {
        this.open = false;
        this.show = this.level === 1;
        this.items.forEach(item => {
            item.closeAndHide();
        });
    }

    private register(item) {
        this.items.push(item);
        item.$on('click', this.onItemClicked);
        item.$on('close', () => this.$emit('close'));
        item.$on('open', level => this.$emit('open', level));
    }

    private onItemClicked(item) {
        // delay 300 when is closing, but why?
        setTimeout(() => {
            this.items.forEach(i => {
                i.show = item.open ? i._uid === item._uid : true;
                if (i._uid !== item._uid) {
                    i.open = false;
                }
            });
        }, item.open && !item.toClose ? 0 : 300);
    }
}
</script>
