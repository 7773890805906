<template>
    <nav>
        <div
            :class="menuStyle"
            class="z-40 w-full text-white transition-all duration-500">
            <div :class="[isSophosMenu ? 'py-2 lg:pt-5 lg:pb-6' : 'py-6 lg:py-12', { '!pr-4': sophos }]" class="flex flex-col h-full container-wide transition-all duration-500">
                <div :class="{ '-ml-0.5 moz:ml-sb': menuOpen }"
                     class="flex z-30 justify-between items-center md:order-1">
                    <a :href="homeLink" :title="logoTitle" :aria-label="navlogoArialabel" class="block pointer-events-auto" :class="{ 'text-berry-700' : (isSophosMenu || lightHeader) && !menuOpen }">
                        <slot :open="isOpen" name="logo" />
                    </a>
                    <div class="flex">
                        <a v-if="contactLink"
                           :href="contactLink"
                           :aria-label="navcontactlinkArialabel"
                           :class="[sophos ? 'rounded-lg' : 'rounded-full']"
                           class="hidden justify-center items-center -mt-1 mr-4 w-14 h-14 bg-black cursor-pointer pointer-events-auto lg:inline-flex lg:mt-0 lg:transition-colors group lg:hover:bg-white">
                            <icon class="w-6 h-6 fill-current lg:transition-colors lg:group-hover:text-black"
                                  name="message-circle-outline" />
                        </a>
                        <div v-if="langNav.length > 1" class="relative hidden lg:block">
                            <div
                                @click.stop="toggleLangNav"
                                :class="[sophos ? 'rounded-lg' : 'rounded-full', { 'pt-1': !langNavFlags }]"
                                class="inline-flex justify-center tracking-wider items-center -mt-1 mr-4 w-14 h-14 bg-black cursor-pointer pointer-events-auto lg:mt-0 lg:transition-colors group lg:hover:bg-white lg:hover:border-black lg:hover:text-black">
                                <icon v-if="langNavFlags" name="globe-2-outline" class="w-6 h-6 fill-current" />
                                <template v-else>
                                    {{ langNav.find(x => x.active).label.split('_')[0] }}
                                </template>
                            </div>
                            <transition name="fade-down">
                                <div v-if="showLangNav" class="absolute top-full flex flex-col space-y-4 mt-4">
                                    <a v-if="langNavFlags"
                                       :aria-label="langNav.find(x => x.active).label.split('_')[0]"
                                       :href="langNav.find(x => x.active).link"
                                       :class="[sophos ? 'rounded-lg' : 'rounded-full lg:transition-colors lg:hover:bg-white lg:hover:border-black lg:hover:text-black', { 'pt-1': !langNavFlags }]"
                                       class="inline-flex justify-center tracking-wider items-center -mt-1 mr-4 w-14 h-14 bg-black cursor-pointer pointer-events-auto lg:mt-0 group">
                                        <icon :name="langNav.find(x => x.active).label.split('_').pop().toLowerCase()"
                                              class="w-6 h-6 fill-current rounded-full border border-white" />
                                    </a>
                                    <a v-for="langItem in langNavItems"
                                       :aria-label="langItem.label.split('_')[0]"
                                       :key="langItem.link"
                                       :href="langItem.link"
                                       :class="[sophos ? 'rounded-lg opacity-75 transition-opacity hover:opacity-100' : 'rounded-full lg:transition-colors lg:hover:bg-white lg:hover:border-black lg:hover:text-black', { 'pt-1': !langNavFlags }]"
                                       class="inline-flex justify-center tracking-wider items-center -mt-1 mr-4 w-14 h-14 bg-black cursor-pointer pointer-events-auto lg:mt-0 group">
                                        <icon v-if="langNavFlags" :name="langItem.label.split('_').pop().toLowerCase()"
                                              class="w-6 h-6 fill-current rounded-full border border-white" />
                                        <template v-else>
                                            {{ langItem.label.split('_')[0] }}
                                        </template>
                                    </a>
                                </div>
                            </transition>
                        </div>
                        <div v-if="!showSearch"
                             :class="[sophos ? 'rounded-lg lg:bg-black' : 'rounded-full bg-black', { 'text-black lg:text-white': isSophosMenu }]"
                             class="search-open inline-flex justify-center items-center -mt-1 mr-4 w-14 h-14 cursor-pointer pointer-events-auto lg:mt-0 lg:transition-colors group lg:hover:bg-white"
                             @click="toggleSearch">
                            <icon class="w-6 h-6 fill-current pointer-events-none lg:transition-colors lg:group-hover:text-black"
                                  name="search-outline" />
                        </div>
                        <div
                            :class="[
                                isOpen && !showSiteNav
                                ? (sophos ? 'text-white lg:text-black lg:bg-black lg:border lg:border-white lg:hover:bg-white' : 'bg-black border border-white lg:border-black lg:bg-white lg:hover:bg-black')
                                : 'lg:hover:bg-white',
                                sophos ? 'lg:bg-black rounded-lg' : 'bg-black rounded-full',
                                { 'text-black lg:text-white lg:bg-black': isSophosMenu, 'bg-black text-white': !isSophosMenu && sophos && lightHeader, 'search-close' : showSearch }]"
                            class="inline-flex justify-center items-center -mt-1 w-14 h-14 cursor-pointer pointer-events-auto lg:mt-0 lg:transition-colors group"
                            @click="toggleMobile">
                            <icon
                                :class="[isOpen && !showSiteNav ? (sophos ? 'lg:group-hover:text-berry-600 lg:text-white' : 'lg:group-hover:text-white lg:text-black') : 'lg:group-hover:text-black']"
                                :name="menuOpen ? 'close' : 'menu'"
                                class="w-6 h-6 fill-current pointer-events-none lg:transition-colors" />
                        </div>
                    </div>
                </div>
                <collapse-transition :duration="500">
                    <div v-show="isOpen"
                         :class="[showSiteNav ? 'h-screen' : 'h-auto']"
                         class="overflow-hidden absolute top-0 left-0 pt-20 w-full bg-black pointer-events-auto lg:flex lg:pt-0 lg:h-screen-available">
                        <div
                            class="flex flex-col justify-between pb-20 lg:pb-0 lg:w-3/4 lg:pt-36 pl-container-wide lg:relative lg:overflow-x-hidden scrollbar">
                            <div v-if="activeLevel > 1"
                                 class="hidden pointer-events-none lg:block lg:absolute lg:top-0 lg:left-0 lg:h-full lg:w-full lg:pl-container-wide lg:pr-9">
                                <div class="lg:h-full lg:w-2/3 lg:border-gray-400 lg:border-r"></div>
                            </div>
                            <div :class="{ 'h-full': activeLevel > 1 }" class="pt-5 pr-9 lg:relative">
                                <slot name="main" />
                            </div>
                            <div class="-ml-9 mt-7 lg:hidden">
                                <slot name="teaser" />
                            </div>
                            <div class="relative pr-9 w-full">
                                <div
                                    :class="[showSiteNav ? 'hidden hmd:inline-block lg:w-full z-20' : 'lg:inline-block lg:w-2/3 z-10']"
                                    class="relative pr-9 lg:px-0 lg:pr-12 scrollbar-hide">
                                    <div class="w-full" v-if="$slots.meta">
                                        <slot name="meta" />
                                    </div>
                                    <div
                                        :class="{ 'border-t': $slots.meta }"
                                        class="py-4 border-gray-300 lg:py-2 lg:flex lg:items-center lg:flex-wrap">
                                        <slot name="footer" :toggle="toggleSiteNav" :show="showSiteNav"
                                              :site-name="siteName" />
                                        <!--                                    <div class="inline-flex items-center mt-4 uppercase cursor-pointer lg:mt-0 lg:mt-1"-->
                                        <!--                                         @click="toggleSiteNav">-->
                                        <!--                                        <icon class="mr-1 mb-1 w-4 h-4 fill-current" name="globe-2" />-->
                                        <!--                                        <span>{{ siteName }}</span>-->
                                        <!--                                        <icon :class="{ 'rotate-180': showSiteNav }"-->
                                        <!--                                              class="mb-1 ml-1 w-7 h-7 transition-transform transform fill-current"-->
                                        <!--                                              name="chevron-down" />-->
                                        <!--                                    </div>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="hidden lg:block lg:w-1/4">
                            <slot name="teaser" />
                        </div>
                        <collapse-transition>
                            <slot v-if="showSiteNav" :show="showSiteNav" :toggle="toggleSiteNav" name="sitenav" />
                        </collapse-transition>
                    </div>
                </collapse-transition>
                <collapse-transition>
                    <search-overlay v-if="showSearch"
                                    :all-results-label="allResultsLabel"
                                    :error-message="errorMessage"
                                    :no-results-message="noResultsMessage"
                                    :placeholder="placeholder"
                                    :results-label="resultsLabel"
                                    :search-link="searchLink" />
                </collapse-transition>
            </div>
        </div>
        <transition name="slide-up">
            <div v-if="isOpen && langNav.length > 1"
                 class="z-40 h-20 lg:hidden flex justify-center items-center fixed bottom-0 left-0 w-full bg-black text-gray-600">
                <template v-for="(langItem, i) in langNav">
                    <div v-if="langItem.active" class="px-2 uppercase text-white" :key="`${langItem.link}-m`">
                        <icon v-if="langNavFlags"
                              :name="langItem.label.split('_').pop().toLowerCase()"
                              class="border rounded-full w-4 h-4 border-berry-600" />
                        <template v-else>
                            {{ langItem.label.split('_')[0] }}
                        </template>
                        <span v-if="!langNavFlags" class="block h-px bg-berry-600 w-full"></span>
                    </div>
                    <a v-else :href="langItem.link"
                       class="block px-2 uppercase transition-colors group"
                       :class="{ 'lg:hover:text-black': !langNavFlags }"
                       :aria-label="langItem.label.split('_')[0]"
                       :key="`${langItem.link}-m`">
                        <icon v-if="langNavFlags"
                              :name="langItem.label.split('_').pop().toLowerCase()"
                              class="border rounded-full w-4 h-4 border-white group-hover:border-berry-600" />
                        <template v-else>
                            {{ langItem.label.split('_')[0] }}
                        </template>
                    </a>
                    <span v-if="i !== langNav.length - 1"
                          class="inline-block mx-4 w-px h-4 bg-gray-600"
                          :key="`sep${i}`"></span>
                </template>
            </div>
        </transition>
    </nav>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Provide } from 'vue-property-decorator';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import Icon from './base/Icon.vue';
import SearchOverlay from './SearchOverlay.vue';

@Component({
    components: { Icon, CollapseTransition, SearchOverlay }
})
export default class MainNavigation extends Vue {
    @Prop({ default: true }) contrast: boolean;
    @Prop({ default: false }) editMode: boolean;
    @Prop({ required: true }) homeLink: string;
    @Prop({ required: true }) searchLink: string;
    @Prop() contactLink: string;
    @Prop({ required: true }) activeSite: string;
    @Prop({ required: true }) backLabel: string;
    @Prop({ default: 'Show all results' }) allResultsLabel: string;
    @Prop({ default: 'Results' }) resultsLabel: string;
    @Prop({ default: 'I am looking for...' }) placeholder: string;
    @Prop({ required: true }) siteNavItems: { label: string; link: string; icon: string; locale: string }[];
    @Prop({ required: true }) errorMessage: string;
    @Prop({ required: true }) noResultsMessage: string;
    @Prop({ default: false, type: Boolean }) sophos: boolean;
    @Prop({ default: false, type: Boolean }) langNavFlags: boolean;
    @Prop({ default: () => [] }) langNav: { active: boolean; label: string; link: string }[];
    @Prop({ default: '' }) logoTitle: string;
    @Prop({ default: false }) lightHeader: boolean;
    @Prop({ required: true }) navlogoArialabel: string;
    @Prop({ required: true }) navcontactlinkArialabel: string;
    @Provide('registerMainNavItem') childRegistration = this.register;
    items = [];
    isOpen = false;
    isTop = true;
    fix = false;
    lastScrollPos = 0;
    activeLevel = 0;
    showSiteNav = false;
    showSearch = false;
    showLangNav = false;
    // get only the local, remove the country
    activeLocale = this.$lang.split('_')[0];
    langNavTimer = null;

    get menuOpen() {
        return this.isOpen || this.showSearch;
    }

    get siteName(): string {
        const activeSite = this.siteNavItems
            .map(x => ({
                title: x.label,
                site: x.link.replace(this.$contextPath, '').replace(/^\/?(\w*).*/, (_, x) => x),
                locale: x.locale
            }))
            .find(x => x.site === this.activeSite && x.locale === this.activeLocale);
        return activeSite ? activeSite.title : '';
    }

    get langNavItems(): { active: boolean; label: string; link: string }[] {
        return this.langNav.filter(x => !x.active);
    }

    get isSophosMenu(): boolean {
        return !this.isTop && this.sophos && !this.menuOpen && this.fix;
    }

    get menuStyle() {
        // [menuOpen ? 'h-full lg:h-screen-available lg:mb-30 scrollbar-black overflow-y-scroll lg:overflow-y-hidden' : 'pointer-events-none lg:h-44', (fix || menuOpen) ? 'fixed animate-from-top lg:h-screen' : (editMode ? 'relative' : 'absolute')]
        let style = '';
        style += this.menuOpen ? 'h-full lg:h-screen-available lg:mb-30 scrollbar-black overflow-y-scroll lg:overflow-y-hidden' : 'pointer-events-none';
        style += ' ';
        style += (this.fix || this.menuOpen) ? 'fixed animate-from-top' : (this.editMode ? 'relative' : 'absolute');
        if (this.menuOpen) {
            style += ' lg:h-screen';
        }
        style += ' ';
        style += this.isSophosMenu ? 'bg-white h-16 lg:h-24' : 'bg-transparent';
        return style;
    }

    mounted() {
        document.addEventListener('scroll', this.onScroll, { passive: false });
    }

    beforeDestroy() {
        document.removeEventListener('scroll', this.onScroll);
    }

    onItemClicked(item) {
        this.items.forEach(i => {
            i.show = item.open ? i._uid === item._uid : true;
            if (i._uid !== item._uid) {
                i.close();
            }
        });
        if (!item.open) {
            this.activeLevel = item.level;
        }
    }

    toggleMobile() {
        if (this.showSearch) {
            this.toggleSearch();
            return;
        }
        this.isOpen = !this.isOpen;
        document.body.classList.toggle('overflow-hidden', this.isOpen);

        if (!this.isOpen) {
            // close all sub items if navigation is closed
            this.items.forEach(item => {
                item.close();
                item.show = true;
            });
            this.activeLevel = 0;
            this.showSiteNav = false;
        } else {
            this.activeLevel = 1;
        }
    }

    toggleSiteNav() {
        if (!this.showSiteNav) {
            this.items.forEach(item => {
                item.closeAndHide();
            });
            this.activeLevel = 1;
        }
        this.showSiteNav = !this.showSiteNav;
    }

    toggleSearch() {
        this.showSearch = !this.showSearch;
        document.body.classList.toggle('overflow-hidden', this.showSearch);
        document.body.classList.toggle('fixed', this.showSearch);
        document.body.classList.toggle('-z-1', this.showSearch);
    }

    toggleLangNav() {
        clearTimeout(this.langNavTimer);
        this.showLangNav = !this.showLangNav;
        if (this.showLangNav) {
            this.langNavTimer = setTimeout(() => {
                this.showLangNav = false;
            }, 5000);
        }
    }

    private onScroll(event: Event) {
        if (this.editMode) {
            return;
        }

        if (this.isOpen || this.showSearch) {
            event.preventDefault();
            return;
        }
        const scrollPos = document.scrollingElement.scrollTop;
        this.isTop = scrollPos === 0;
        this.fix = scrollPos < this.lastScrollPos;
        this.lastScrollPos = scrollPos;
    }

    private register(item) {
        this.items.push(item);
        item.$on('click', this.onItemClicked);
        item.$on('close', this.toggleMobile);
        item.$on('open', level => {
            this.activeLevel = level;
        });
    }
}
</script>
