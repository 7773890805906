<template>
    <div class="bg-gray-500 text-white p-8">
        <h1 v-if="title" class="text-4xl text-bold mb-4">{{ title }}</h1>
        <div v-if="text" v-html="text" />
        <a v-if="link" class="underline mt-6 inline-block" :href="link">Read more</a>
        <slot name="image" />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
class Dummy extends Vue {
    @Prop() title: string;
    @Prop() text: string;
    @Prop() link: string;
}

export default Vue.component('dummy', Dummy);

</script>
