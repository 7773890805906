<template>
    <div class="relative">
        <label class="form-input" :for="changeName">
            <input
                type="text"
                :placeholder="label"
                :class="{ 'form-input-validate': notvalid, '!border-b-2': bigBorder}"
                @input="onChange"
                autocomplete="new-password"
                :required="required"
                v-model="search"
                @click="cleanSearch"
                @focus="onFocus"
                @keyup.down="onArrowDown"
                @keyup.up="onArrowUp"
                @keyup.enter.stop="onEnter" />
            <input type="hidden" :id="changeName" :name="changeName" :value="value" />
            <span class="form-placeholder" :class="[placeholderClass, {'form-placeholder-top': placeholderUp } ]">{{ label }}</span>
            <span v-if="notvalid" class="form-validate" :class="placeholderClass">
                {{ validateText }}
            </span>
        </label>
        <div
            v-show="isOpen"
            class="absolute z-10 overflow-y-auto w-full"
            :class="dropdownClass"
            style="-webkit-overflow-scrolling: touch;"
            @touchstart.stop
            @touchmove.stop>
            <div>
                <ul class="bg-white text-black">
                    <li
                        v-for="(result, i) in results"
                        :key="i"
                        class="cursor-pointer px-4 hover:bg-gray-100"
                        :class="dropdownitemClass"
                        @mousedown.prevent="setResult(result)">
                        {{ result.label }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    name: 'AutoCompleteInput',
    props: {
        items: {
            type: Array
        },
        placeholderClass: {
            type: String,
            default: ''
        },
        name: String,
        label: String,
        validateText: String,
        notvalid: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        sendEnglish: {
            type: Boolean,
            default: false
        },
        bigBorder: {
            type: Boolean,
            default: false
        },
        dropdownClass: {
            type: String,
            default: 'max-h-[11.5rem] top-10 shadow-lg'
        },
        dropdownitemClass: {
            type: String,
            default: 'py-2'
        },
        defaultId: String
    },
    data() {
        return {
            isOpen: false,
            results: [],
            search: '',
            value: '',
            validCountry: false,
            arrowCounter: 0,
            changeName: 'changeName',
            placeholderUp: false
        };
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
        const findIntro = this.items.filter(item => {
            return (item.id === this.defaultId || (this.defaultId === 'uk' && item.id === 'gb'));
        });
        if (findIntro[0] && findIntro[0].label) {
            this.search = findIntro[0].label;
            this.value = this.sendEnglish ? findIntro[0].label_en : findIntro[0].label;
            this.validCountry = true;
            this.placeholderUp = true;
        }
        // edge don't read the autocomplete
        // solution have a random name in the input and then change to the original input name
        setTimeout(() => { this.changeName = this.name; }, 500);
    },

    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },

    methods: {
        filterResults() {
            this.results = this.items.filter(item => {
                // search contries that start with seatch
                return item.label.toLowerCase().startsWith(this.search.toLowerCase());
            });
        },

        onChange() {
            this.$emit('input', this.search);
            this.validCountry = false;
            this.filterResults();
            this.isOpen = true;
        },

        setResult(result) {
            this.search = result.label;
            this.value = this.sendEnglish ? result.label_en : result.label;
            this.placeholderUp = true;
            this.validCountry = true;
            this.isOpen = false;
        },

        onArrowDown() {
            if (this.arrowCounter < this.results.length) {
                this.arrowCounter = this.arrowCounter + 1;
            }
        },

        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1;
            }
        },

        onEnter(event) {
            this.isOpen = false;
            this.arrowCounter = this.arrowCounter >= 0 ? this.arrowCounter : 0;
            if (event.key === 'Enter' && this.results[this.arrowCounter] && this.search !== '') {
                this.setResult(this.results[this.arrowCounter]);
            } else if (!this.validCountry) {
                this.cleanSearch();
            }
            this.placeholderUp = this.value;
            this.arrowCounter = -1;
        },

        onFocus() {
            this.placeholderUp = true;
        },

        handleClickOutside(evt) {
            this.arrowCounter = this.arrowCounter >= 0 ? this.arrowCounter : 0;
            if (!this.$el.contains(evt.target)) {
                this.isOpen = false;
                this.placeholderUp = this.value;
            }
            if (this.search !== '' && !this.validCountry) {
                this.cleanSearch();
                this.isOpen = false;
                this.placeholderUp = this.value;
            }
            this.arrowCounter = -1;
        },

        cleanSearch() {
            this.search = '';
            this.value = '';
            this.isOpen = true;
            this.onChange();
        }
    },
    watch: {
        items(val, oldValue) {
            if (val.length !== oldValue.length) {
                this.results = val.label;
            }
        }
    }
};
</script>
