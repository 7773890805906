import { DirectiveBinding } from 'vue/types/options';

export default {
    bind(element: HTMLElement, binding: DirectiveBinding) {
        const wordsPerMinute = 200;
        // get all elements
        let elements = null;
        if (binding.value) {
            elements = document.querySelectorAll(binding.value);
        } else {
            elements = Array.from(document.getElementsByTagName('main'));
        }
        // get all text to count
        let combinedText = '';
        elements.forEach((element: HTMLElement) => {
            combinedText += ' ' + (element.textContent || '');
        });
        // calculate reading time
        const textLength = combinedText.split(' ').length;
        if (textLength > 0) {
            const value = Math.ceil(textLength / wordsPerMinute);
            element.innerText = value.toString();
        } else {
            element.innerText = '0';
        }
    }
};
