<template>
    <a @click.prevent="onClick"
       :href="'#' + anchor"
       v-smooth-scroll="{ offset: -100 }"
       :aria-label="title"
       class="block flex-auto shrink-0 mr-32 lg:mr-52 w-48 lg:w-auto active:text-black"
       :class="[
           styleContentNavigation === 'contrast' ?
           (active ? 'text-white' : 'text-gray-600 hover:text-white')
           : (active ? 'text-black' : 'text-gray-600 hover:text-black')]">
        <icon v-if="!sophos" name="gft-angle" class="mb-2 w-5 h-5 fill-current" />
        {{ title }}
    </a>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Inject, Prop, Watch } from 'vue-property-decorator';
import Icon from './base/Icon.vue';

@Component({
    components: { Icon }
})
export default class ContentNavigationItem extends Vue {
    @Inject({
        default: () => null
    }) readonly registerNavItem!: Function;

    @Prop({ default: 'default' }) styleContentNavigation: string;
    @Prop({ required: true }) anchor: string;
    @Prop({ required: true }) title: string;
    @Prop({ default: false, type: Boolean }) sophos: string;
    active = false;
    section: HTMLElement = null;
    clicked = false;

    mounted() {
        this.registerNavItem(this);
        if (!this.section) {
            this.section = document.getElementById(this.anchor);
        }
    }

    onClick() {
        if (this.section) {
            this.clicked = true;
            this.active = true;
            location.hash = this.anchor;
        }
    }

    @Watch('active')
    change() {
        if (this.active) {
            this.$nextTick(() => {
                if (this.active && this.section) {
                    this.$emit('activated', { offset: this.section.offsetTop, clicked: this.clicked });
                    this.clicked = false;
                }
            });
        }
    }
}
</script>
