import Vue from 'vue';

export const SectionObserver = {
    data() {
        return {
            // has to be an object (and not just a primitive value) in order to make it reactive with provide
            currentSection: {
                contrast: false,
                element: null
            }
        };
    },
    provide() {
        return {
            currentSection: this.currentSection
        };
    },
    methods: {
        initSectionObserver(selector = 'section', contrastClass = 'text-white') {
            if (!this.$refs.sectionObserverRoot) {
                // no ref set, abort
                return;
            }
            // get all sections
            const sections = this.$refs.sectionObserverRoot.querySelectorAll(selector);
            window.onscroll = () => {
                const windowHeight = window.innerHeight;
                for (let i = 0; i < sections.length; i++) {
                    const section: HTMLElement = sections.item(i);
                    // get section values
                    const sectionHeight = section.scrollHeight;
                    const sectionStart = section.offsetTop;
                    const sectionEnd = sectionStart + sectionHeight;

                    // only change active when it's in the middle of the screen
                    if (
                        scrollY >= sectionStart - windowHeight / 2 &&
                        scrollY <= sectionEnd - windowHeight / 2
                    ) {
                        // use vue set in order to ensure reactivity for nested object
                        Vue.set(this.currentSection, 'element', section);
                        // check if section has the contrast class
                        Vue.set(this.currentSection, 'contrast', section.classList.contains(contrastClass));
                        // no need to check the rest of the sections
                        return;
                    }
                }
                // no section found so fall back to default values
                Vue.set(this.currentSection, 'element', null);
                Vue.set(this.currentSection, 'contrast', false);
            };
        }
    }
};
