import { DirectiveBinding } from 'vue/types/options';
import Vue from 'vue';

let calculateHeight;
export default {
    bind(element: HTMLElement, binding: DirectiveBinding) {
        calculateHeight = () => {
            if (binding.value) {
                const elements = element.querySelectorAll(binding.value);

                // remove any minHeight applied before
                elements.forEach((element: HTMLElement) => {
                    element.style.minHeight = '';
                });
                Vue.nextTick(() => {
                    // calculate the minHeight necessary
                    let maxHeight = 0;
                    elements.forEach((element: HTMLElement) => {
                        const computedStyle = window.getComputedStyle(element);
                        const height = parseFloat(computedStyle.height);
                        maxHeight = Math.max(maxHeight, height);
                    });
                    // apply to all elements
                    elements.forEach((element: HTMLElement) => {
                        element.style.minHeight = `${maxHeight}px`;
                    });
                });
            }
        };
        // apply every time is resize
        window.addEventListener('resize', calculateHeight);
        // apply when is mounted
        calculateHeight();
    },
    unbind() {
        window.removeEventListener('resize', calculateHeight);
    }
};
