<template>
    <div v-if="show" @click="onClick" :class="{ 'cursor-pointer': enableClick }">
        <slot :active="active" />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Inject, Prop } from 'vue-property-decorator';

@Component
class GftSwiperSlide extends Vue {
    @Inject({
        default: () => null
    }) readonly registerSlide!: Function;

    @Prop() id: string;
    @Prop() title: string;
    @Prop() subTitle: string;
    @Prop() kicker: string;
    @Prop() text: string;
    @Prop() url: string;
    @Prop() target: string;
    @Prop() label: string;
    @Prop() image: string;
    @Prop({ default: '' }) imageAlt: string;
    @Prop() icon: string;
    @Prop({ type: Boolean, default: false }) editMode: boolean;
    @Prop({ type: Boolean, default: false }) enableClick: boolean;
    @Prop({ type: Boolean, default: false }) contrast: boolean;

    show = true;
    active = false;

    mounted() {
        if (!this.editMode) {
            this.registerSlide(this);
        }
    }

    onClick() {
        if (this.enableClick) {
            this.$emit('click', this);
        }
    }

    get imageUrl(): string {
        if (this.image) {
            return this.image.startsWith(this.$contextPath) ? this.image : `${this.$contextPath}${this.image}`;
        }
        return '';
    }

    get _id(): string {
        return this.id ? this.id : `${this._uid}`;
    }
}

export default Vue.component('gft-swiper-slide', GftSwiperSlide);

</script>
