let onScroll;

/**
 * Directive to trigger classes while an element is scrolled in view
 * Usage:
 * 1. set v-in-view directive on parent element that should be scrolled into view
 * 2. set 'data-in-view' attribute with css classes to add to the element while its parent is in view
 * these classes will be removed if the parent element is scrolled out of view
 *
 * Note: "in view" means the top of the element is at least at the top of the screen
 *
 * TODO: try with intersection observer with a ratio of 1
 */
export default {
    bind(element: HTMLElement) {
        const fixElements: NodeList = element.querySelectorAll('[data-in-view]');

        onScroll = () => {
            // data-visible attribute on element indicates if it is currently scrolled into view
            const inView = element.hasAttribute('data-visible');
            const rect = element.getBoundingClientRect();
            if (inView && (rect.height - Math.abs(rect.top) < 0 || rect.top > 0)) {
                // element leaves view (bottom of parent is scrolled into view or top is below top of screen)
                delete element.dataset.visible; // remove visible flag
                fixElements.forEach((el: HTMLElement) => {
                    if (el.dataset.inView) {
                        // remove classes from 'data-in-view' if any
                        el.classList.remove(...el.dataset.inView.trim().split(' '));
                    }
                });
            } else if (!inView && rect.top < 0 && rect.height - Math.abs(rect.top) > 0) {
                // element comes into view (top of element reaches top of screen or bottom of element comes in from the top)
                element.dataset.visible = ''; // set visible flag
                fixElements.forEach((el: HTMLElement) => {
                    if (el.dataset.inView) {
                        // add classes from 'data-in-view' if any
                        el.classList.add(...el.dataset.inView.trim().split(' '));
                    }
                });
            }
        };
        document.addEventListener('scroll', onScroll);
    },
    unbind() {
        // cleanup
        document.removeEventListener('scroll', onScroll);
    }
};
