<template>
    <div class="h-full">
        <a v-if="link"
           class="inline-flex relative flex-col shrink-0 justify-end p-9 w-full h-full transition-colors duration-300 lg:p-16 lg:static"
           :href="link"
           :aria-label="nameLabel"
           :target="target"
           :class="[{ 'bg-opacity-85': active && !contrast }, { 'bg-opacity-80': active && contrast }, { 'bg-opacity-50': !active }, contrast ? 'bg-black active-slide:bg-opacity-80' : 'bg-white active-slide:bg-opacity-75']"
           @mouseenter="$emit('hover', id);">
            <div class="absolute top-0 left-0 w-full h-full transition-opacity -z-1"
                :class="[active ? 'opacity-100': 'lg:opacity-0']">
                <slot name="image" />
            </div>
            <slot :active="active" />
        </a>
        <div v-else
           class="inline-flex relative flex-col shrink-0 justify-end p-9 w-full h-full transition-colors duration-300 lg:p-16 lg:static"
           :class="[{ 'bg-opacity-85': active && !contrast }, { 'bg-opacity-80': active && contrast }, { 'bg-opacity-50': !active }, contrast ? 'bg-black active-slide:bg-opacity-80' : 'bg-white active-slide:bg-opacity-75']"
           @mouseenter="$emit('hover', id);">
            <div class="absolute top-0 left-0 w-full h-full transition-opacity -z-1"
                :class="[active ? 'opacity-100': 'lg:opacity-0']">
                <slot name="image" />
            </div>
            <slot :active="active" />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Inject, Prop } from 'vue-property-decorator';

@Component
class HeroTextMediaColumnsItem extends Vue {
    @Inject({
        default: () => null
    }) readonly registerHeroTextMediaColumnsItem!: Function;

    @Prop() link: string;
    @Prop() target: string;
    @Prop() nameLabel: string;
    @Prop({ default: false }) contrast: boolean;

    id = null;
    active = false;

    mounted() {
        this.id = this._uid;
        this.registerHeroTextMediaColumnsItem(this);
    }
}

export default Vue.component('hero-text-media-columns-item', HeroTextMediaColumnsItem);

</script>
