export default {
    inserted(element) {
        const updateProgress = () => {
            const scrollAmount = window.scrollY;
            const sectionTop = element.offsetTop - window.innerHeight;
            const sectionHeight = element.offsetHeight;

            // calculate the % of the scroll section
            let scrollProgress = (scrollAmount - sectionTop) / sectionHeight;
            scrollProgress = Math.max(0, Math.min(scrollProgress, 1)); // limit between 0% and 100%.

            // find the black circle
            const blackCircle = element.querySelector('circle[id="circle"]');
            if (blackCircle) {
                // calculate the new stroke-dasharray
                const circumference = 2 * Math.PI * blackCircle.getAttribute('r');
                const dashArrayValue = scrollProgress * circumference;

                // update the stroke-dasharray of the black circle
                blackCircle.style.strokeDasharray = `${dashArrayValue}, ${circumference}`;
            }
        };

        window.addEventListener('scroll', updateProgress);
        // call to define the inicial value
        updateProgress();

        // Clean up
        (element)._cleanup = () => {
            window.removeEventListener('scroll', updateProgress);
        };
    },
    unbind(element) {
        if ((element)._cleanup) {
            (element)._cleanup();
        }
    }
};
