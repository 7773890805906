import Vue from 'vue';
import GftFootnote from '../components/base/GftFootnote.vue';

export default {
    bind(element: HTMLElement) {
        const content = element.innerHTML;
        const ComponentClass = Vue.extend(GftFootnote);
        const instance = new ComponentClass({
            propsData: {
                content
            }
        });
        element.innerHTML = '';
        instance.$mount();
        element.appendChild(instance.$el);
    }
};
