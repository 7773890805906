<template>
    <div class="flex flex-col lg:grid lg:grid-cols-2">
        <div class="py-16 pl-container-wide pr-9 lg:py-10 xl:py-28"
             :class="[{ 'text-white': contrast },{ 'pr-9 lg:pr-16 pb-16': imageNormal }]"
             v-if="!showForm && !formSent">
            <slot />
            <div class="pt-6 lg:pt-7 lg:pb-0" :class="{ 'text-white': !contrast }">
                <button class="tr-gated-download-open-form flex justify-center inline-block overflow-hidden px-12 pt-4 pb-3 w-full text-center text-ellipsis transition-colors lg:w-max"
                        :class="[ contrast ? 'bg-white text-black hover:bg-gray-200' : 'bg-berry-600 hover:bg-berry-700']"
                        @click="showForm = true">
                    <icon name="plus-square-outline"
                          class="pointer-events-none inline-block w-5 h-5 align-middle rotate-180 fill-current" />
                    <span class="pointer-events-none font-bold ml-1.5">{{ buttonText }}</span>
                </button>
            </div>
        </div>
        <div v-if="editMode || showForm || formSent" class="pr-9 lg:pr-16 pl-container-wide"
             :class="[ contrast ? 'text-white' : 'text-black', formSent && !imageNormal ? 'pt-20' : 'py-20', { 'min-h-screen-50': formSent }]">
            <button v-if="!formSent" @click="showForm = false" class="flex items-center mb-2" :class="{ 'opacity-0': formSent }">
                <icon name="chevron-left" class="-mt-px w-6 h-7 fill-current" />
                <span class="ml-1.5 font-bold">
                    {{ backLabel }}
                </span>
            </button>
            <slot name="form" />
        </div>
        <div class="-mx-9 lg:mx-0">
            <slot name="image" />
        </div>
    </div>
</template>
<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Icon from './base/Icon.vue';

@Component({
    components: { Icon }
})
class GatedDownload extends Vue {
    @Prop({ default: false }) editMode: boolean;
    @Prop({ default: false }) formSent: boolean;
    @Prop({ default: false }) imageNormal: boolean;
    @Prop({ default: false }) contrast: boolean;
    @Prop() buttonText: string;
    @Prop() backLabel: string;
    showForm = false;
}

export default Vue.component('gated-download', GatedDownload);
</script>
