<template>
    <div class="flex fixed top-0 left-0 z-50 justify-center items-center w-screen h-screen bg-black/80 transition-opacity duration-500 lg:py-32"
         :class="[show ? 'opacity-100' : 'opacity-0']">
        <div class="container h-full text-center">
            <div v-if="image"
                 class="relative -mx-9 h-full lg:mx-0"
                 :class="{ 'flex flex-col items-center lg:inline-block': !!size }">
                <img :src="image"
                     :alt="caption"
                     @load="imageLoaded"
                     :style="imgSize"
                     :class="{ 'scale-100': loaded }"
                     class="object-contain object-center relative top-0 left-0 mx-auto w-full h-full origin-center scale-0 transform-transform opacity mt-1/2 lg:mt-0">
                <div v-if="caption && !!size" class="px-9 mx-auto text-right text-white line-clamp-4 lg:px-0" :style="width">
                    {{ caption }}
                </div>
                <div v-if="description && !!size" class="px-9 mx-auto text-right text-white lg:px-0" :style="width">
                    {{ description }}
                </div>
            </div>
            <div v-else-if="youtubeId" class="relative -mx-9 h-full lg:mx-0 flex justify-center items-center">
                <div class="relative h-0 pt-5/3 self-center w-full">
                    <iframe
                        ref="youtubeIframe"
                        allowfullscreen mozallowfullscreen msallowfullscreen oallowfullscreen webkitallowfullscreen
                        class="youtube-iframe object-contain absolute w-full h-full top-0 left-0 object-left"
                        :src="srcYoutube"
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture;"
                    ></iframe>
                </div>
            </div>
            <div v-else-if="vimeoLink" class="relative -mx-9 h-full lg:mx-0 flex justify-center items-center">
                <div class="relative h-0 pt-5/3 self-center w-full">
                    <gft-video
                        :link="vimeoLink"
                        small-icon
                        class="object-contain absolute w-full h-full top-0 left-0 object-left"
                    ></gft-video>
                </div>
            </div>
        </div>
        <div class="absolute top-0 right-0 left-0 w-full container-wide">
            <div class="relative">
                <div class="flex absolute right-0 top-5 justify-center items-center p-2 w-14 h-14 text-white rounded-full border border-white transition-colors cursor-pointer lg:top-12 hover:text-black hover:bg-white"
                     @click="close">
                    <icon name="close" class="w-6 h-6 transition-colors fill-current" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from './base/Icon';

export default {
    components: { Icon },
    props: {
        image: String,
        caption: String,
        description: String,
        youtubeId: String,
        vimeoLink: String
    },
    data() {
        return {
            loaded: false,
            show: false,
            img: null,
            size: null
        };
    },
    created() {
        document.addEventListener('keyup', this.onEsc);
    },
    mounted() {
        this.$nextTick(() => {
            this.show = true;
        });
    },
    beforeUnmount() {
        document.removeEventListener('keyup', this.onEsc);
    },
    methods: {
        imageLoaded(evt) {
            this.loaded = true;
            this.img = evt.target;
            setTimeout(() => {
                this.size = this.getRenderedSize();
            }, 500);
        },
        close() {
            this.show = false;
            setTimeout(() => {
                this.$emit('close');
            }, 500);
        },
        getRenderedSize() {
            const width = this.img.naturalWidth;
            const height = this.img.naturalHeight;
            const cWidth = this.img.width;
            const cHeight = this.img.height;
            const oRatio = width / height;
            const cRatio = cWidth / cHeight;
            return (function() {
                if (oRatio > cRatio) {
                    this.width = cWidth;
                    this.height = cWidth / oRatio;
                } else {
                    this.width = cHeight * oRatio;
                    this.height = cHeight;
                }
                this.left = (cWidth - this.width) / 2;
                this.right = this.width + this.left;
                return this;
            }.call({}));
        },
        onEsc(event) {
            if (event.key === 'Escape' && this.show) {
                this.close();
            }
        }
    },
    computed: {
        width() {
            if (!this.size) {
                return {};
            }
            return {
                width: `${this.size.width}px`
            };
        },
        imgSize() {
            if (!this.size) {
                return {};
            }
            return {
                width: `${this.size.width}px`,
                height: `${this.size.height}px`
            };
        },
        srcYoutube() {
            return 'https://www.youtube.com/embed/' + this.youtubeId + '?playsinline=1&showinfo=0&rel=0&enablejsapi=1';
        }
    }
};
</script>
