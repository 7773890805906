<template>
    <div class="accordion" :class="compClass">
        <slot name="header" :open="visible" :clickToogle="toggle">
            <button class="px-9 w-full text-left focus:outline-none"
                    :class="{ 'border-gray-400/30': !visible, 'border-b': !visible && !noBorder }"
                    @click="toggle">
                <span class="flex content-between items-center py-7">
                    <slot name="title">
                        <span class="max-w-accordion text-xl">{{ title }}</span>
                    </slot>
                    <icon name="chevron-down"
                          :class="{ 'rotate-180': visible }"
                          class="mr-4 shrink-0 ml-auto w-7 h-7 fill-current transition-transform" />
                </span>
            </button>
        </slot>
        <collapse-transition>
            <div v-show="visible"
                 class="px-9 py-5 border-gray-400/30"
                 :class="{ 'border-b': !noBorder && visible }">
                <slot />
            </div>
        </collapse-transition>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import Icon from '../base/Icon.vue';

@Component({
    components: {
        Icon,
        CollapseTransition
    }
})
export default class BlogAccordion extends Vue {
    @Prop({ default: '' }) title: string;
    @Prop({ default: false, type: Boolean }) open!: boolean;
    @Prop({ default: false, type: Boolean }) noBorder!: boolean;
    @Prop({ default: '' }) group: string;
    @Prop({ default: false, type: Boolean }) inset: boolean;
    @Prop({ default: 'px-9 py-5 border-b border-gray-400/30' }) showClass: string;
    @Prop({ default: '-mx-9 lg:mx-auto' }) compClass: string;
    visible = this.open;

    created() {
        this.$root.$on('accordion-open', this.onAccordionOpened);
        window.addEventListener('hashchange', this.checkHash);
    }

    mounted() {
        this.checkHash();
    }

    beforeDestroy() {
        this.$root.$off('accordion-open', this.onAccordionOpened);
    }

    checkHash() {
        if (this.$attrs.id && this.$attrs.id.length && `#${this.$attrs.id}` === location.hash && !this.visible) {
            setTimeout(() => {
                this.toggle();
            }, 1000);
        }
    }

    onAccordionOpened(group: string) {
        if (this.group === '') {
            return;
        }
        if (this.group === group) {
            this.visible = false;
        }
    }

    toggle() {
        if (!this.visible) {
            this.$root.$emit('accordion-open', this.group);
        }
        this.visible = !this.visible;
        this.$emit('toggle', this.visible);
    }
}
</script>
