var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fixed-scroll-button',{attrs:{"num-items":_vm.icons.length,"open-down":_vm.openDown},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var getPos = ref.getPos;
return [_vm._l((_vm.icons),function(icon,i){return _c('div',{key:icon,ref:"share-items",refInFor:true,staticClass:"hidden lg:block absolute bottom-0 p-4 w-14 h-14 text-4xl text-white bg-black rounded-full border border-white transition-all cursor-pointer group hover:bg-white hover:border-black",style:(getPos(i, false)),attrs:{"data-type":icon,"id":'tr-share-' + icon}},[_c('icon',{staticClass:"pointer-events-none w-full h-full text-white transition-colors fill-current group-hover:text-black",attrs:{"name":icon}})],1)}),_vm._l((_vm.icons),function(icon,i){return _c('div',{key:icon+'-mobile',ref:"share-items",refInFor:true,staticClass:"absolute lg:hidden bottom-0 p-4 w-14 h-14 text-4xl text-white bg-black rounded-full border border-white transition-all cursor-pointer group hover:bg-white hover:border-black",style:(getPos(i, true)),attrs:{"data-type":icon,"id":'tr-share-' + icon}},[_c('icon',{staticClass:"pointer-events-none w-full h-full text-white transition-colors fill-current group-hover:text-black",attrs:{"name":icon}})],1)})]}},{key:"toggle",fn:function(ref){
var toggle = ref.toggle;
var contrast = ref.contrast;
var hidden = ref.hidden;
var open = ref.open;
return [_vm._t("default",null,{"toggle":toggle,"contrast":contrast,"hidden":hidden,"open":open})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }