import Vue from 'vue';
import OverviewCategory from './OverviewCategory.vue';
import AsyncOverview from './AsyncOverview.vue';
import AsyncOverviewFilter from './AsyncOverviewFilter.vue';
import OverviewFilters from './OverviewFilters.vue';

Vue.component('overview-category', OverviewCategory);
Vue.component('async-overview', AsyncOverview);
Vue.component('async-overview-filter', AsyncOverviewFilter);
Vue.component('overview-filters', OverviewFilters);
