<template>
    <div>
        <video ref="video"
               :poster="image"
               class="object-cover object-center absolute top-0 left-0 w-full h-full"
               preload="metadata"
               controls
               playsinline
               webkit-playsinline
               @play="onPlayPause"
               @pause="onPlayPause">
            <source :src="`${link}${image ? '' : '#t=0.001'}`" type="video/mp4" />
        </video>
        <transition name="fade">
            <div v-if="overlayVisible"
                 class="flex absolute top-0 left-0 justify-center items-center w-full h-full text-white bg-black/40">
                <div class="flex justify-center items-center w-16 h-16 rounded-full border border-white cursor-pointer"
                     :class="{ 'lg:w-36 lg:h-36' : smallIcon }"
                     @click="play">
                    <icon name="arrow-right-outline" class="w-11 h-11 fill-current" />
                </div>
            </div>
        </transition>
        <div v-if="!editMode && !hasMarketingCookie" class="absolute top-0 left-0 w-full h-full">
            <div class="absolute text-white top-1/2 lg:top-1/5 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <div class="text-xl text-center" :class="{'lg:text-2xl': smallInfo }">
                    {{ overlay }}
                </div>
            </div>
            <div class="absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <div class="flex justify-center items-center w-full h-full">
                    <a href="javascript:Cookiebot.renew()" :aria-label="overlay" class="flex justify-center items-center w-16 h-16 rounded-full border border-white cursor-pointer lg:w-36 lg:h-36">
                        <icon name="arrow-right-outline" class="w-11 h-11 fill-current text-white" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Ref } from 'vue-property-decorator';
import Icon from './base/Icon.vue';
import Helper from '../libs/Helper';

@Component({
    components: { Icon }
})
class GftVideo extends Vue {
    @Prop({ required: true }) link: string;
    @Prop() image: string;
    @Prop() overlay: string;
    @Prop({ type: Boolean, default: false }) editMode: boolean;
    @Prop({ type: Boolean, default: false }) smallIcon: boolean;
    @Prop({ type: Boolean, default: false }) smallInfo: boolean;
    @Ref('video') video: HTMLVideoElement;

    isPaused = true;
    overlayTimer = null;
    overlayVisible = true;
    hasMarketingCookie = false;

    created() {
        this.checkCookie();
        window.addEventListener('CookiebotOnAccept', () => {
            this.checkCookie();
        });
    }

    checkCookie() {
        const cookie = Helper.getCookie('CookieConsent');
        this.hasMarketingCookie = cookie && cookie.includes('marketing:true');
    }

    play() {
        if (!this.video) {
            return;
        }
        this.video.play();
    }

    onPlayPause() {
        clearTimeout(this.overlayTimer);
        this.isPaused = this.video.paused;
        if (this.isPaused) {
            this.showOverlay();
        } else {
            this.overlayVisible = false;
        }
    }

    showOverlay() {
        clearTimeout(this.overlayTimer);
        this.overlayTimer = setTimeout(() => {
            this.overlayVisible = true;
        }, 3000);
    }
}

export default Vue.component('gft-video', GftVideo);

</script>
