<template>
    <div ref="el">
        <slot />
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { gsap } from 'gsap';

@Component({})
export default class BlogNext extends Vue {
    @Prop({ required: true }) link: string;
    @Ref('el') el: HTMLElement;
    @Ref('pin') pin: HTMLElement;

    progress = 0;
    scrollTimeout: number | null = null;
    pageLoaded = false;

    mounted() {
        // to know if the page is fully loaded
        window.onload = this.setPageLoaded;

        gsap.timeline({
            ease: 'none',
            scrollTrigger: {
                trigger: this.el,
                start: 'bottom bottom',
                end: '+=150%',
                scrub: 0.2,
                pin: this.el
            },
            onComplete: this.loadNextBlog
        })
            .add(gsap.fromTo(this.el, { clipPath: 'inset(0% 90% 0% 0%)' }, { clipPath: 'inset(0% 0% 0% 0%)' }));
    }

    loadNextBlog() {
        // the page is completly loaded
        if (this.pageLoaded) {
            // add page to the history's browser (so person can return back)
            history.pushState({ path: this.link }, '', this.link);

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'blog-endless-mode',
                term: this.link
            });
            window.location.href = this.link;
        } else {
            console.warn('Page not loaded or user not detected');
        }
    }

    setPageLoaded() {
        this.pageLoaded = true;
    }
}
</script>
