<template>
    <div
        v-if="open"
        class="w-full pt-7 pb-6 bottom-0 lg:py-6 z-30 border-t-3 border-gray-100 bg-white grid grid-cols-1 transition-all duration-500 ease-out"
        :class="[editMode ? '': 'fixed', show ? '' : '-bottom-40']"
    >
        <div class="row-start-1 col-start-1 h-full w-full">
            <div class="flex justify-end container-wide lg:h-full">
                <button class="text-black focus:outline-none lg:self-center -mr-5 -mt-5 lg:m-0" @click="close">
                    <icon name="plus"
                          class="shrink-0 w-5 h-5 fill-current rotate-45"/>
                </button>
            </div>
        </div>
        <div class="container flex justify-between items-center row-start-1 col-start-1">
            <slot/>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Icon from './base/Icon.vue';

@Component({
    components: {
        Icon
    }
})
class ContactBar extends Vue {
    @Prop({ default: false }) editMode: boolean;
    show = false;
    open = true;

    mounted() {
        document.addEventListener('scroll', this.onScroll);
    }

    beforeUnmount() {
        document.removeEventListener('scroll', this.onScroll);
    }

    onScroll() {
        const scrollTop = document.documentElement.scrollTop;
        const clientHeight = document.body.clientHeight;
        this.show = (scrollTop > clientHeight / 4);
    }

    close() {
        this.open = false;
    }
}

export default Vue.component('contact-bar', ContactBar);

</script>
