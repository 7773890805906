<template>
    <div class="relative w-full h-full overflow-x-hidden" @mouseenter="pauseAutoplay" @mouseleave="resumeAutoplay">
        <div
            class="flex scrollbar-hide relative w-full border-0"
            ref="slider">
            <div
                :class="editMode ? 'flex-wrap' : 'animate-ticker-a flex-nowrap'"
                class="px-6 w-fit flex flex-row gap-12"
                :style="durationA"
                ref="content">
                <slot />
            </div>
            <div
                class="px-6 w-fit flex flex-row flex-nowrap gap-12 animate-ticker-b"
                :style="durationB"
                ref="duplicatecontent">

            </div>
        </div>
        <div :class="showCustomCursor ? 'absolute lg:flex cursor-none' : 'hidden'"
             :style="{ left: `${cursor.x}px`, top: `${cursor.y}px` }"
             class="pointer-events-none z-30 uppercase tracking-wider text-black hidden px-4 -mt-10 -ml-10 items-center justify-center">
            <div class="pt-full w-full absolute rounded-full bg-white left-0 top-0 flex justify-center items-center">
                <div class="absolute top-1/2 -translate-y-1/2 mt-0.5">{{ cursorText }}</div>
            </div>
            <div class="relative opacity-0">{{ cursorText }}</div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Ref } from 'vue-property-decorator';

@Component({})
class GftInifiteAutoplay extends Vue {
    @Prop({ type: Boolean, default: false }) editMode: boolean;
    @Prop({ type: String, default: '' }) cursorText: string;
    @Ref('slider') slider: HTMLElement;
    @Ref('content') content: HTMLElement;
    @Ref('duplicatecontent') duplicatecontent: HTMLElement;

    numChars = 3;
    speed = 0.5;
    spaceMultiplier = 1;
    currentSpaceMultiplier = 0;
    durationMultiplier = 1;
    playState: 'paused' | 'running' = 'paused';
    items = [];
    cursor = { x: 0, y: 0 };
    showCustomCursor = false;

    mounted() {
        this.items = Array.from(this.content.children);
        if (!this.editMode) {
            this.prepareInfiniteScroll();
            window.addEventListener('resize', this.calculateSpaceMultiplier);
            window.addEventListener('mousemove', this.onMouseMove);
        }
    }

    unmounted() {
        if (!this.editMode) {
            this.items.forEach(item => {
                item.removeEventListener('mouseover', this.hideCursor);
                item.removeEventListener('mouseleave', this.showCursor);
            });
            window.removeEventListener('resize', this.calculateSpaceMultiplier);
            window.removeEventListener('mousemove', this.onMouseMove);
        }
    }

    prepareInfiniteScroll() {
        this.$nextTick(() => {
            this.spaceMultiplierUpdate();
            this.durationMultiplierUpdate();
            this.addItems(this.spaceMultiplier ? this.spaceMultiplier : 1);
            setTimeout(() => {
                this.startAnimation();
            }, 1000);
        });
    }

    startAnimation() {
        this.playState = 'running';
    }

    pauseAutoplay() {
        this.playState = 'paused';
    }

    resumeAutoplay() {
        this.playState = 'running';
    }

    spaceMultiplierUpdate() {
        this.spaceMultiplier = this.slider && this.content ? Math.ceil(this.slider.clientWidth / this.content.clientWidth) : 1;
    }

    durationMultiplierUpdate() {
        this.durationMultiplier = this.slider && this.content ? (Math.ceil(this.content.clientWidth / this.slider.clientWidth)) * 100 : 100;
    }

    calculateSpaceMultiplier() {
        this.pauseAutoplay();
        this.spaceMultiplierUpdate();
        if (!this.currentSpaceMultiplier || this.currentSpaceMultiplier < this.spaceMultiplier) {
            this.addItems(this.spaceMultiplier - this.currentSpaceMultiplier);
        }
        this.durationMultiplierUpdate();
        this.resumeAutoplay();
    }

    addItems(amount) {
        const empty = Array.from(this.duplicatecontent.children).length === 0;
        for (let i = 0; i < amount; i++) {
            this.items.forEach(child => {
                const cloneForContent = child.cloneNode(true);
                const cloneForDuplicate = child.cloneNode(true);
                if (i > 0 || !empty) {
                    this.content.appendChild(cloneForContent);
                }
                this.duplicatecontent.appendChild(cloneForDuplicate);
            });
        }
        const content = Array.from(this.content.children);
        content.forEach(item => {
            item.addEventListener('mouseover', this.hideCursor);
            item.addEventListener('mouseleave', this.showCursor);
        });
        const duplicated = Array.from(this.duplicatecontent.children);
        duplicated.forEach(item => {
            item.addEventListener('mouseover', this.hideCursor);
            item.addEventListener('mouseleave', this.showCursor);
        });
        this.currentSpaceMultiplier = this.spaceMultiplier;
    }

    get durationA() {
        return {
            animationDuration: `${this.durationMultiplier / this.speed}s`,
            animationDelay: `-${this.durationMultiplier / this.speed}s`,
            animationPlayState: this.playState
        };
    }

    get durationB() {
        return {
            animationDuration: `${this.durationMultiplier / (this.speed)}s`,
            animationDelay: `-${this.durationMultiplier / (this.speed * 2)}s`,
            animationPlayState: this.playState
        };
    }

    // cursor functions
    hideCursor() {
        this.showCustomCursor = true;
    }

    showCursor() {
        this.showCustomCursor = false;
    }

    onMouseMove(event: MouseEvent) {
        this.cursor.x = event.clientX - this.slider.getBoundingClientRect().left;
        this.cursor.y = event.clientY - this.slider.getBoundingClientRect().top;
    }
}

export default Vue.component('gft-infinite-autoplay', GftInifiteAutoplay);
</script>
