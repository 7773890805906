/* eslint-disable dot-notation */
import axios from 'axios';

const VALIDATE_MAIL_ENDPOINT = '/.rest/api/v1/cta/validate-email';

async function sendRequest(url: string, data: FormData): Promise<{ el?: Element; success: boolean}> {
    const id = data.get('mgnlModelExecutionUUID');
    try {
        const res = await axios({
            method: 'post',
            url,
            data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        const html = document.createElement('html');
        html.innerHTML = res.data;
        let success = html.querySelector(`#success-${id}`);
        let error = html.querySelector('#formErrorsDisplay');
        // if no element is found also search templates (e.g. in case of cta section)
        if (!success) {
            const templates = Array.from(html.querySelectorAll('template'));
            let i = 0;
            while (!success && i < templates.length) {
                // templates are document fragments so we need to access content
                success = templates[i].content.querySelector(`#success-${id}`);
                i++;
            }
        }
        if (!success) {
            const templates = Array.from(html.querySelectorAll('template'));
            let i = 0;
            while (!error && i < templates.length) {
                // templates are document fragments so we need to access content
                error = templates[i].content.querySelector('#formErrorsDisplay');
                i++;
            }
            return { el: error, success: false };
        }
        return { el: success, success: true };
    } catch (err) {
        console.error(err);
        return { success: false };
    }
}

async function onSubmit(event: Event) {
    // prevent form submit which triggers page reload
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const fd: FormData = new FormData(form);

    try {
        const emailFields = Array.from(form.elements).filter(x => x['type'] === 'email') as HTMLInputElement[];
        const results = await Promise.all(emailFields.map(async (x: HTMLInputElement) => {
            try {
                const res = await fetch(`${window['contextPath']}${VALIDATE_MAIL_ENDPOINT}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ email: x.value })
                });
                if (!res.ok) {
                    return false;
                }
                return await res.json();
            } catch (_) {
                return false;
            }
        }));

        // if (results.some(x => !x)) {
        //     results.forEach((x, i) => {
        //         if (!x) {
        //             emailFields[i].setCustomValidity('Email address not allowed');
        //             emailFields[i].reportValidity();
        //         }
        //     });
        //     return;
        // }

        // disarm hp field
        form.querySelector('input.b-t').removeAttribute('required');

        if (form.checkValidity()) {
            const result = await sendRequest(form.action, fd);
            if (result.success) {
                const parent = form.parentElement;
                form.remove();
                parent.append(result.el);
            } else if (result.el) {
                const errorMessage = form.querySelector('#formErrorsDisplay');
                if (errorMessage) {
                    errorMessage.remove();
                }
                form.prepend(result.el);
            }
        } else {
            // rearm hp field
            form.querySelector('input.b-t').setAttribute('required', 'required');
            for (let i = 0; i < form.elements.length; i++) {
                if ((form.elements[i] as HTMLFormElement).classList.contains('b-t')) {
                    continue; // skip hp validate
                } else if (!(form.elements[i] as HTMLFormElement).reportValidity()) {
                    break; // trigger html validation messages
                }
            }
        }
    } catch (err) {
        // TODO: handle exception
        console.error(err);
        return false;
    }
}

export default {
    bind(element: HTMLElement) {
        if (element.tagName.toLowerCase() !== 'form') {
            return;
        }
        element.onsubmit = ev => onSubmit(ev);
    }
};
