<template>
    <div
        id="tr-like-increment"
        @click="increase"
        :class="[disableClick ? 'cursor-default' : 'cursor-pointer']">
        <slot :disableClick="disableClick" :currentLikes="currentLikes"></slot>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import axios from 'axios';

const BLOG_LIKE_ENDPOINT = '/.rest/api/v1/blogs/like';

@Component
export default class BlogIncrement extends Vue {
    @Prop() uuid: string;
    @Prop() likes: number;

    currentLikes = 0;
    disableClick = false;
    created() {
        this.currentLikes = this.likes;
    }

    async increase() {
        if (!this.disableClick) {
            try {
                await axios.post(`${this.$contextPath}${BLOG_LIKE_ENDPOINT}/${this.$site}/${this.$lang}?blog=${this.uuid}`);
                this.currentLikes++;
                this.disableClick = true;
            } catch (e) {
                console.error(e);
            }
        }
    }
}
</script>
