<template>
    <div class="relative w-full">
        <div v-if="editMode">
            <div class="my-8 text-lg cursor-pointer border-l-6 border-success-green">
                <div class="p-4 border border-l-0 border-gray-400">
                    Stock chart placeholder, the chart is only visible with "Preview in tab" and on the public instances!<br>
                    Active chart: <b><slot /></b>
                </div>
            </div>
        </div>
        <template v-else-if="hasMarketingCookie || previewMode">
            <iframe :id="uuid" :src="stock"></iframe>
        </template>
        <div v-else class="my-8 text-lg cursor-pointer border-l-6 border-error-red" @click="openCookieBot">
            <div class="p-4 border border-l-0 border-gray-400">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import Helper from '../libs/Helper';

const StockChart = {
    props: {
        uuid: String,
        stock: String,
        editMode: Boolean,
        previewMode: Boolean
    },
    data() {
        return {
            hasMarketingCookie: false,
            initialized: false
        };
    },
    created() {
        window.addEventListener('CookiebotOnAccept', this.checkAndInit);
    },
    mounted() {
        this.checkAndInit();
    },
    beforeDestroy() {
        window.removeEventListener('CookiebotOnAccept', this.checkAndInit);
    },
    methods: {
        checkAndInit() {
            this.checkCookie();
            this.$nextTick(() => {
                this.init();
            });
        },
        init() {
            if ((!this.hasMarketingCookie || this.initialized) && !this.previewMode) {
                return;
            }
            // check for loading order of charts, this is needed if there are more than one charts on the site
            // only one chart should initialise the EurolandToolIntegrationObject, all others should register if it already exists
            if (window.loadingEIAHAH == null && window.EurolandToolIntegrationObject == null) {
                window.loadingEIAHAH = true;

                const eurolandScript = document.createElement('script');
                eurolandScript.setAttribute('src', 'https://tools.euroland.com/tools/common/eurolandiframeautoheight/eurolandtoolsintegrationobject.js');
                this.$el.appendChild(eurolandScript);

                eurolandScript.onload = () => {
                    window.EurolandToolIntegrationObject.set(this.uuid);
                    window.loadingEIAHAH = false;

                    this.$root.$emit('EIAHAHloaded');
                };
            } else if (window.loadingEIAHAH && window.EurolandToolIntegrationObject == null) {
                this.$root.$on('EIAHAHloaded', () => {
                    window.EurolandToolIntegrationObject.set(this.uuid);
                });
            } else {
                window.EurolandToolIntegrationObject.set(this.uuid);
            }
            this.initialized = true;
        },
        checkCookie() {
            const cookie = Helper.getCookie('CookieConsent');
            this.hasMarketingCookie = cookie && cookie.includes('marketing:true');
        },
        openCookieBot() {
            if (window.Cookiebot) {
                window.Cookiebot.renew();
            }
        }
    }
};

export default Vue.component('stock-chart', StockChart);
</script>
