<template>
    <div class="flex relative justify-center items-center mx-auto w-16 h-16">
        <icon name='gft-circle' class="absolute w-full h-full fill-current flesh-shrink-0 animate-spin-slow" />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Icon from './Icon.vue';

@Component({
    components: { Icon }
})
export default class Throbber extends Vue {
}

</script>
