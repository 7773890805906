<template>
    <div class="overflow-y-auto absolute bottom-0 left-0 z-10 pt-0 pb-28 w-full h-full bg-black lg:pt-32 lg:pb-0">
        <div class="container-wide">
            <div class="flex sticky top-0 z-10 items-center pt-28 pb-6 text-3xl bg-black cursor-pointer lg:pt-14 lg:text-50"
                 @click="$emit('close')">
                <icon class="mb-px -ml-2 w-10 h-10 fill-current" name="chevron-left" />
                {{ title }}
            </div>
            <a :href="global.link" class="flex items-center py-1.5 group lg:py-4 lg:text-2xl" :aria-labelledby="global.label">
                <div :class="{ 'opacity-50': activeSite !== global.site }"
                     class="flex overflow-hidden justify-center items-center mr-6 w-6 h-6 rounded-full border border-white transition-all bg-blue-900 lg:w-8 lg:h-8 lg:mr-9 group-hover:scale-125 group-hover:opacity-100">
                    <icon class="w-full h-full fill-current" name="globe" />
                </div>
                <div :class="{ 'text-gray-300': activeSite !== global.site }"
                     class="transition-colors group-hover:text-white">
                    {{ global.label }}
                </div>
            </a>
            <div class="pt-6 pb-12 lg:grid lg:grid-cols-3 lg:grid-rows-5 lg:grid-flow-col">
                <a v-for="country in countries"
                   :aria-labelledby="country.label"
                   :key="country.label"
                   :href="country.link"
                   class="flex items-center py-1.5 group lg:text-2xl lg:py-2">
                    <div :class="{ 'opacity-50': !((activeSite === country.site) && (country.locale === activeLocale)) }"
                         class="flex overflow-hidden justify-center items-center mr-6 w-6 h-6 rounded-full border border-white transition-all lg:w-8 lg:h-8 lg:mr-9 group-hover:scale-125 group-hover:opacity-100">
                        <icon :name="country.icon" class="w-full h-full rounded-full" />
                    </div>
                    <div :class="{ 'text-gray-300': !((activeSite === country.site) && (country.locale === activeLocale)) }"
                         class="transition-colors group-hover:text-white">
                        {{ country.label }}
                    </div>
                </a>
            </div>
            <div class="lg:hidden">
                <slot :site="activeSiteName" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Icon from './base/Icon.vue';

@Component({
    components: { Icon }
})
class SiteNavigation extends Vue {
    @Prop({ required: true }) title: string;
    @Prop({ required: true }) activeSite: string;
    @Prop({ required: true }) navItems: { label: string; link: string; icon: string; locale: string }[];

    // get only the local, remove the country
    activeLocale = this.$lang.split('_')[0];

    get countries() {
        const globalIndex = this.navItems.findIndex(x => !!x.link.match(/\/int($|\/)/));
        if (globalIndex >= 0) {
            // remove int link from list (handled separately)
            // add "fake" site name to object by stripping contextPath and language from link
            return this.navItems
                .slice(0, globalIndex)
                .concat(this.navItems.slice(globalIndex + 1))
                .map(x => ({ ...x, site: x.link.replace(this.$contextPath, '').replace(/^\/?(\w*).*/, (_, x) => x) }));
        }
        return this.navItems;
    }

    get global() {
        if (this.countries.length) {
            return { ...this.navItems.find(x => !!x.link.match(/\/int($|\/)/)), site: 'int' };
        }
        return null;
    }

    get activeSiteName(): string {
        const item = this.navItems
            .map(x => ({ ...x, site: x.link.replace(this.$contextPath, '').replace(/^\/?(\w*).*/, (_, x) => x) }))
            .find(x => x.site === this.activeSite);
        return item ? item.label : '';
    }
}

export default Vue.component('site-navigation', SiteNavigation);

</script>
