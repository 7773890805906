var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"accordion",class:_vm.compClass},[_vm._t("header",function(){
var _obj, _obj$1;
return [_c('button',{staticClass:"px-9 w-full text-left focus:outline-none ",class:[( _obj = {
                    'lg:border-b':                      !_vm.visible && _vm.styling === 'default',
                    'lg:border-b border-gray-400':      !_vm.visible && (_vm.styling === 'semi' || _vm.styling === 'contrast'),
                    'bg-white    hover:bg-gray-200':          !_vm.visible && !_vm.location && _vm.styling === 'default',
                    'bg-gray-200 hover:bg-white':             !_vm.visible && !_vm.location && _vm.styling === 'semi',
                    'bg-black    hover:bg-gray-800': !_vm.visible && !_vm.location && _vm.styling === 'contrast' ,
                }, _obj[_vm.darkTextColor] = (!_vm.visible && (!_vm.location || _vm.sophos)) && (_vm.styling === 'semi' || _vm.styling === 'default'), _obj[_vm.lightTextColor] = (!_vm.visible && (!_vm.location || _vm.sophos)) && _vm.styling === 'contrast', _obj[_vm.lightTextColor] = (_vm.visible && (!_vm.location || _vm.sophos))  && (_vm.styling === 'semi' || _vm.styling === 'default'), _obj[_vm.darkTextColor] = (_vm.visible && (!_vm.location || _vm.sophos))  && _vm.styling === 'contrast', _obj['bg-black'] =      _vm.visible && !_vm.location && !_vm.sophos && (_vm.styling === 'semi' || _vm.styling === 'default'), _obj['bg-white'] =      _vm.visible && !_vm.location && !_vm.sophos && _vm.styling === 'contrast', _obj['bg-berry-600'] =  _vm.visible && _vm.sophos, _obj )],on:{"click":_vm.toggle}},[_c('span',{staticClass:"flex content-between items-center py-7"},[_vm._t("title",function(){return [_c('span',{staticClass:"max-w-accordion"},[_vm._v(_vm._s(_vm.title))])]}),_c('icon',{staticClass:"shrink-0 ml-auto w-5 h-5 fill-current",class:[( _obj$1 = {}, _obj$1[_vm.lightTextColor] = !_vm.visible    && _vm.color === 'contrast', _obj$1[_vm.darkTextColor] = _vm.visible     && _vm.color === 'semi' || _vm.color === 'default', _obj$1 )],attrs:{"name":[ _vm.visible ? 'minus' : 'plus']}})],2),_c('span',{staticClass:"block border-gray-400 lg:border-b-0",class:{ 'border-b': !_vm.visible }})])]},{"open":_vm.visible,"clickToogle":_vm.toggle}),_c('collapse-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],class:_vm.showClass},[_vm._t("default")],2)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }