<template>
    <div>
        <div class="hidden overflow-hidden relative z-10 divide-x divide-white max-h-screen-90 lg:h-[45.5rem] lg:grid"
             :class="'lg:grid-cols-' + gridColumns">
            <slot />
        </div>
        <gft-swiper class="h-screen-80 lg:hidden"
                    slide-classes="w-11/12"
                    ref="slider"
                    wrapper-classes="divide-x divide-current"
                    @index-changed="onSliderUpdate">
            <slot />
        </gft-swiper>
    </div>
</template>

<script lang="ts">
import Vue, { VNode } from 'vue';
import { Component, Prop, Provide, Ref, Watch } from 'vue-property-decorator';
import HeroTextMediaColumnsItem from './HeroTextMediaColumnsItem.vue';
import GftSwiper from '../GftSwiper.vue';

@Component({
    components: { GftSwiper }
})
class HeroTextMediaColumns extends Vue {
    @Provide('registerHeroTextMediaColumnsItem') childRegistration = this.register;
    @Ref('slider') slider: InstanceType<typeof GftSwiper>;
    @Prop({ required: true, default: 4 }) components: number;

    items: InstanceType<typeof HeroTextMediaColumnsItem>[] = [];
    index = 0;

    sliderOptions = {
        gap: 0,
        perPage: 4,
        fixedHeight: '100vh',
        autoHeight: true,
        breakpoints: {
            640: {
                perPage: 1
            },
            1200: {
                perPage: 2
            },
            1680: {
                perPage: 3
            }
        }
    };

    register(item: InstanceType<typeof HeroTextMediaColumnsItem>) {
        this.items.push(item);
        item.$on('hover', id => {
            this.index = this.items.findIndex(x => x.id === id);
        });
        if (this.$slots.default.filter(this.filterItems).length === this.items.length && this.items.length > 0) {
            this.items[0].active = true;
        }
    }

    filterItems(child: VNode) {
        return child && child.componentOptions && child.componentOptions.tag === 'hero-text-media-columns-item';
    }

    onSliderUpdate(index) {
        this.index = index;
    }

    @Watch('index')
    updateSlides() {
        this.items.forEach(x => {
            x.active = false;
        });
        if (this.items.length > this.index) {
            this.items[this.index].active = true;
        }
    }

    get gridColumns() {
        return Math.min(this.components, 4);
    }
}

export default Vue.component('hero-text-media-columns', HeroTextMediaColumns);

</script>
