<template>
    <div class="-mx-9 lg:hidden">
        <button class="px-9 w-full text-left focus:outline-none" @click="toggle">
            <span :class="{ 'text-gray-600 border-b': !active }"
                  class="flex justify-between items-center pt-12 pb-6">
                <span class="block w-full">
                    <span class="block text-base tracking-wider uppercase">{{ kicker }}</span>
                    <span class="text-3xl flex items-center justify-between">
                        {{ name }}
                        <icon :class="[contrast ? 'text-white' : 'text-black']"
                              :name="[ active ? 'minus' : 'plus']"
                              class="shrink-0 ml-4 ml-auto w-5 h-5 fill-current" />
                    </span>
                </span>
            </span>
        </button>
        <collapse-transition v-show="active">
            <div :class="[contrast ? 'bg-black text-white' : 'text-black']"
                 class="px-9 py-10 border-b-4 border-berry-600">
                <slot />
            </div>
        </collapse-transition>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import { Component, Inject, Prop } from 'vue-property-decorator';
import Icon from './base/Icon.vue';

@Component({
    components: { Icon, CollapseTransition }
})
class Client extends Vue {
    @Inject({
        default: () => null
    }) readonly registerClient!: Function;

    @Prop() uuid: string;
    @Prop() kicker: string;
    @Prop() name: string;
    @Prop() description: string;
    @Prop() link: string;
    @Prop() linkLabel: string;
    @Prop() logo: string;
    @Prop({ default: '' }) logoAlt: string;
    @Prop() image: string;
    @Prop({ default: '' }) imageAlt: string;
    @Prop({ default: false }) contrast: boolean;
    @Prop({ default: '' }) group: string;

    active = false;

    mounted() {
        this.registerClient(this);
    }

    created() {
        this.$root.$on('accordion-open', this.onAccordionOpened);
    }

    beforeDestroy() {
        this.$root.$off('accordion-open', this.onAccordionOpened);
    }

    onAccordionOpened(group) {
        if (this.group === '') {
            return;
        }
        if (this.group === group) {
            this.active = false;
        }
    }

    toggle() {
        if (!this.active) {
            this.$root.$emit('accordion-open', this.group);
        }
        this.active = !this.active;
    }
}

export default Vue.component('client', Client);

</script>
