<template>
    <div>
        <slot />
        <div class="hidden grid-cols-12 gap-x-24 items-start lg:grid place-content-between max-w-full">
            <div
                class="z-10 col-span-3 col-start-1 row-start-1 items-center pb-10 self-center justify-self-start w-full"
                :class="{ 'text-gray-600': sophos }">
                <div v-for="useCase in useCases"
                     :key="useCase.uuid"
                     :class="[useCase.active ? '-mr-4 pr-12' : 'text-gray-600 mr-8', { 'text-berry-600': useCase.active && sophos, 'border-gray-300': sophos }]"
                     class="flex justify-between py-4 border-b border-current cursor-pointer transition-all duration-500"
                     @mouseenter="hover(useCase)">
                    <h3 class="pr-4 line-clamp-2 text-inherit" :class="{ 'uppercase tracking-wider': !sophos }">{{ useCase.title }}</h3>
                </div>
            </div>
            <div class="relative col-span-6 col-start-4 row-start-1 h-160 grid">
                <div class="relative grid z-10 w-full place-self-center"
                     :class="[active && active.rendition === 'landscape' ? 'h-[30rem]' : 'h-full']">
                    <template v-for="useCase in useCases">
                        <div class="absolute grid"
                             :key="`img-bg-${useCase.uuid}`"
                             v-if="useCase.rendition && useCase.active"
                             :class="{
                                       'bg-gray-200': !useCase.contrast && !sophos,
                                       'bg-berry-500/20': !useCase.contrast && sophos,
                                       'bg-gray-800': useCase.contrast && !sophos,
                                       'bg-berry-700': useCase.contrast && sophos,
                                       'left-0 h-2/3 top-1/6 right-0 xl:right-1/6': useCase.rendition === 'reel',
                                       'w-1/2 -top-1/12 -bottom-1/12 right-1/12' : useCase.rendition === 'landscape',
                                       'w-1/2 right-0 lg:w-9/12 -top-1/12 h-full' : useCase.rendition === 'portrait',
                                       'opacity-100' : useCase.active,
                                       'opacity-0' : !useCase.active,
                                       'rounded-4xl': sophos
                                    }"></div>

                        <div v-if="(useCase.type !== 'image' || !useCase.type) && useCase.active"
                             :key="`video-${useCase.uuid}`"
                             v-proto.inline.top="'Video'"
                             class="relative h-0 pt-16/9 self-center">
                            <gft-video v-if="useCase.type === 'vimeoLink' && useCase.active"
                                       :key="`vimeo-${useCase.uuid}`"
                                       v-proto.inline.top="'Embedded Link'"
                                       :overlay="overlay"
                                       :link="useCase.media"
                                       :edit-mode="isEditMode"
                                       :image="useCase.thumbnail"
                                       class="object-contain absolute w-full h-full top-0 left-0 object-left"></gft-video>
                            <template v-if="useCase.type === 'youtubeId' && useCase.active">
                                <iframe v-if="isEditMode"
                                        class="cookieconsent-optin-marketing object-contain absolute w-full h-full top-0 left-0 object-left"
                                        :src="`https://www.youtube.com/embed/${useCase.media}?playsinline=1&showinfo=0&rel=0`"
                                        frameborder="0"
                                        data-cookieconsent="marketing"
                                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen></iframe>
                                <iframe v-else
                                        class="cookieconsent-optin-marketing object-contain absolute w-full h-full top-0 left-0 object-left"
                                        :data-cookieblock-src="`https://www.youtube.com/embed/${useCase.media}?playsinline=1&showinfo=0&rel=0`"
                                        frameborder="0"
                                        data-cookieconsent="marketing"
                                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen></iframe>
                                <div v-if="!isEditMode"
                                     class="cookieconsent-optout-marketing absolute left-0 top-1/2 w-full -translate-y-1/2 pt-16/9">
                                    <div class="absolute w-full h-full top-0 left-0">
                                        <img v-if="useCase.thumbnail" :src="useCase.thumbnail"
                                             class="absolute w-full h-full top-0 left-0 object-cover object-center">
                                        <div class="absolute bg-black/40 w-full h-full top-0 left-0"></div>
                                    </div>
                                    <div
                                        class="absolute text-white top-1/5 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
                                        <div class="text-xl lg:text-2xl text-center py-8 lg:py-18">
                                            {{ cookieOverlay }}
                                        </div>
                                    </div>
                                    <div class="absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                        <div class="flex justify-center items-center w-full h-full rounded-full">
                                            <a href="javascript:Cookiebot.renew()"
                                               :aria-label="cookieOverlay"
                                               class="flex justify-center items-center w-16 h-16 rounded-full border border-white cursor-pointer lg:w-36 lg:h-36">
                                                <icon name="arrow-right-outline"
                                                      class="w-11 h-11 fill-current text-white" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <img v-else-if="(!useCase.type || useCase.type === 'image') && useCase.media && useCase.active"
                             :key="`img-uc-${useCase.uuid}`"
                             :class="{ 'opacity-100' : useCase.active,
                                       'opacity-0' : !useCase.active,
                                       'top-0 h-full object-left': useCase.rendition === 'reel' || useCase.rendition === 'landscape',
                                       '-top-1/12 h-full lg:py-6 -left-1/12 xl:left-0 object-left': useCase.rendition === 'portrait'}"
                             :src="useCase.media"
                             :alt="useCase.mediaAlt"
                             class="object-contain absolute w-full transition-opacity duration-500 left-0">
                    </template>
                </div>
            </div>
            <div class="col-span-3 col-start-10 row-start-1 self-center justify-self-start">
                <div v-for="useCase in useCases" :key="`text-${useCase.uuid}`">
                    <div v-if="useCase.active">
                        <div :class="{ 'bg-black text-white' : useCase.contrast, 'bg-white': !useCase.contrast }">
                            <h3 class="text-3xl mb-4" :class="{ 'text-berry-600': sophos }">{{ useCase.title }}</h3>
                            <div v-html="useCase.text"></div>
                        </div>
                        <div v-if="useCase.linkUrl && useCase.linkLabel">
                            <div v-if="useCase.linkStyle && useCase.linkStyle === 'button'">
                                <div class="flex">
                                    <a :href="`${ useCase.linkUrl }`"
                                       class="self-start font-bold justify-center items-center py-4 -mx-9 px-16 mt-7 text-white bg-berry-600 lg:mx-0 hover:bg-berry-700 transition-colors">
                                        <span>{{ useCase.linkLabel }}</span>
                                    </a>
                                </div>
                            </div>
                            <div v-else>
                                <a :href="`${ useCase.linkUrl }`"
                                   class="flex items-center mt-7 text-lg font-medium text-berry-600">
                                    <icon class="mr-1 w-4 h-5 fill-current" name="chevron-right"></icon>
                                    {{ useCase.linkLabel }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Provide } from 'vue-property-decorator';
import Icon from './base/Icon.vue';
import GftVideo from './GftVideo.vue';

@Component({ components: { Icon, GftVideo } })
export default class UseCases extends Vue {
    @Provide('registerCase') childRegistration = this.registerCase;
    @Prop({ default: false }) contrast: boolean;
    @Prop() overlay: string;
    @Prop() cookieOverlay: string;
    @Prop({ default: false }) isEditMode: boolean;
    @Prop({ default: false }) sophos: boolean;

    useCases = [];

    get active() {
        return this.useCases.find(c => c.active);
    }

    registerCase(useCase) {
        this.useCases.push(useCase);
        if (this.$children.filter(this.filterCases).length === this.useCases.length) {
            if (this.useCases.length > 0) {
                this.$nextTick(() => {
                    this.useCases[0].toggle();
                });
            }
        }
    }

    hover(useCase) {
        this.useCases.forEach(c => {
            c.active = false;
        });
        useCase.active = true;
    }

    filterCases(child: Vue) {
        return child.$vnode && child.$vnode.componentOptions && child.$vnode.componentOptions.tag === 'case';
    }
}
</script>
