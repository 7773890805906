<template>
    <div class="flex overflow-x-hidden scrollbar-hide relative w-full" :class="[showbig ? '' : 'py-2', showborder? 'border-b border-gray-600' : 'border-0']">
        <ul v-proto="'buzzwords'"
            v-if="items.length"
            ref="orig"
            :style="durationA"
            :class="[(reverseanimation ? 'animate-ticker-a-reverse' : 'animate-ticker-a'), (showbig ? 'lg:text-8xl pb-1 text-6xl leading-tight' : 'text-5xl leading-snug'), (colorText ? colorText : whitetext ? 'text-white': 'text-black')]"
            class="flex flex-nowrap pl-3 space-x-3 font-normal list-none transform-gpu lg:pt-5 lg:pb-4">
            <li class="whitespace-nowrap" v-for="(item, a) in extendedItems" :key="`${item}-${a}-a`">
                {{ item }} |
            </li>
        </ul>
        <ul v-proto="'buzzwords'"
            v-if="items.length"
            :style="durationB"
            :class="[(reverseanimation ? 'animate-ticker-b-reverse' : 'animate-ticker-b'), (showbig ? 'lg:text-8xl pb-1 text-6xl leading-tight' : 'text-5xl leading-snug'), (colorText ? colorText : whitetext ? 'text-white' : 'text-black')]"
            class="flex flex-nowrap pl-3 space-x-3 font-normal list-none transform-gpu lg:pt-5 lg:pb-4">
            <li class="whitespace-nowrap" v-for="(item, b) in extendedItems" :key="`${item}-${b}-b`">
                {{ item }} |
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Ref } from 'vue-property-decorator';

@Component
class GftTicker extends Vue {
    @Prop() items: string[];
    @Prop({ default: 2 }) speed: number;
    @Prop({ default: false }) showbig: boolean;
    @Prop({ default: false }) showborder: boolean;
    @Prop({ default: false }) whitetext: boolean;
    @Prop({ default: '' }) colorText: string;
    @Prop({ default: false }) reverseanimation: boolean;
    @Ref('orig') list: HTMLElement;
    playState: 'paused' | 'running' = 'paused';
    multiplier = 1;

    mounted() {
        this.$nextTick(() => {
            // calculate multiplier to extend items to span screen width
            this.multiplier = Math.ceil(this.$el.clientWidth / this.list.clientWidth);
            setTimeout(() => {
                this.startAnimation();
            }, 1000);
        });
    }

    startAnimation() {
        this.playState = 'running';
    }

    get numChars() {
        return this.extendedItems.reduce((r, x) => r + x.length, 0);
    }

    get durationA() {
        return {
            animationDuration: `${this.numChars / this.speed}s`,
            animationDelay: `-${this.numChars / this.speed}s`,
            animationPlayState: this.playState
        };
    }

    get durationB() {
        return {
            animationDuration: `${this.numChars / this.speed}s`,
            animationDelay: `-${this.numChars / (this.speed * 2)}s`,
            animationPlayState: this.playState
        };
    }

    // conditionally multiply item array to span screen width
    get extendedItems() {
        const items = [];
        for (let i = 0; i < this.multiplier; i++) {
            items.push(...this.items);
        }
        return items;
    }
}

export default Vue.component('gft-ticker', GftTicker);

</script>
