<template>
    <div :class="classes">
        <label for="email" class="form-input" :class="{'form-input-email': newsletter}">
            <input type="email" @blur="checkEmail" name="email" id="email" required v-model="emailInput" :placeholder="placeholder">
            <span>{{ placeholder }} *</span>
            <span v-if="notvalid && emailInvalid && showError && (topic === 'business' || this.topic === 'newsletter')"
                class="form-validate form-validate-not-hide">{{ corporateError }}</span>
            <span v-else-if="notvalid && (showError || !emailInput)"
                class="form-validate form-validate-not-hide">{{ emailError }}</span>
        </label>
    </div>
</template>

<script lang="ts">
const VALIDATE_MAIL_ENDPOINT = '/.rest/api/v1/cta/validate-email';

export default {
    name: 'EmailInput',
    props: {
        notvalid: {
            type: Boolean,
            default: false
        },
        emailInvalid: {
            type: Boolean,
            default: false
        },
        newsletter: {
            type: Boolean,
            default: false
        },
        placeholder: String,
        corporateError: String,
        emailError: String,
        topic: String,
        classes: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            showError: false,
            emailInput: ''
        };
    },
    methods: {
        // check email when after input is out of focus
        async checkEmail() {
            const emailCheck = this.emailInput;
            // check if is a valid email
            this.showError = !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*\.[a-zA-Z]{2,}$/.test(emailCheck));
            // if is valid and is business check if is a corporate email
            if (!this.showError && (this.topic === 'business' || this.topic === 'newsletter')) {
                try {
                    const res = await fetch(`${this.$contextPath}${VALIDATE_MAIL_ENDPOINT}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ email: emailCheck })
                    });
                    if (!res.ok) {
                        throw new Error(`HTTP error! Status: ${res.status}`);
                    }
                    const valid = await res.json();
                    this.showError = !valid;
                } catch (error) {
                    console.error(error);
                }
            }
        }
    }
};
</script>
