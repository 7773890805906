<template>
    <div class="inline-block text-berry-600 relative">
        <icon name="info-outline" class="fill-current w-5 h-5 translate-y-1" ref="icon" />
        <div ref="tooltip"
             v-show="visible"
             class="absolute footnote max-w-xs w-max shadow bg-gray-200 px-5 py-4 prose prose-footnote">
            <div class="footnote-arrow" ref="arrow"></div>
            <div v-html="content"></div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Ref, Prop } from 'vue-property-decorator';
import Icon from './Icon.vue';
import { popperGenerator, defaultModifiers } from '@popperjs/core/lib/popper-lite';
import flip from '@popperjs/core/lib/modifiers/flip';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';
import offset from '@popperjs/core/lib/modifiers/offset';
import arrow from '@popperjs/core/lib/modifiers/arrow';
import eventListeners from '@popperjs/core/lib/modifiers/eventListeners';

@Component({
    components: { Icon }
})
export default class GftFootnote extends Vue {
    @Ref('icon') icon: Icon;
    @Ref('tooltip') tooltip: HTMLElement;
    @Ref('arrow') arrow: HTMLElement;
    @Prop() content: string;

    popper = null;
    visible = false;
    timeout: NodeJS.Timeout = null;

    mounted() {
        const createPopper = popperGenerator({
            defaultModifiers: [...defaultModifiers, flip, preventOverflow, offset, arrow, eventListeners]
        });
        this.popper = createPopper(this.icon.$el, this.tooltip, {
            placement: 'auto',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8]
                    }
                },
                {
                    name: 'arrow',
                    options: {
                        element: this.arrow
                    }
                },
                { name: 'preventOverflow', options: { padding: 8 } },
                { name: 'flip' }
            ]
        });

        const showEvents = ['mouseenter', 'focus'];
        const hideEvents = ['mouseleave', 'blur'];

        showEvents.forEach((event) => {
            this.icon.$el.addEventListener(event, this.show);
            this.tooltip.addEventListener(event, this.show);
        });

        hideEvents.forEach((event) => {
            this.icon.$el.addEventListener(event, this.hide);
            this.tooltip.addEventListener(event, this.hide);
        });
    }

    show() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        this.visible = true;
        // Enable the event listeners
        this.popper.setOptions((options) => ({
            ...options,
            modifiers: [
                ...options.modifiers,
                { name: 'eventListeners', enabled: true }
            ]
        }));

        // Update its position
        this.popper.update();
    }

    hide() {
        this.timeout = setTimeout(() => {
            if (this.visible) {
                this.visible = false;
                // Disable the event listeners
                this.popper.setOptions((options) => ({
                    ...options,
                    modifiers: [
                        ...options.modifiers,
                        { name: 'eventListeners', enabled: false }
                    ]
                }));
            }
        }, 200);
    }
}
</script>
